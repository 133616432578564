import React from 'react';
import {ControlLabel,Row,Col,Radio} from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';

class KedMeasure extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checkBox:{},
            quantitativeDatePickerValues:{},
            validationDates:[],
            dynamicEntries: [],
            validationMessage:[],
            serviceDescriptionDetails:{}
        }
        this.handleQuantitativeTestDateChange = this.handleQuantitativeTestDateChange.bind(this);
        this.sendDynamicCaptureData = this.sendDynamicCaptureData.bind(this);
        this.populateEditFields = this.populateEditFields.bind(this); 
        this.updateDynamicEntries = this.updateDynamicEntries.bind(this); 
    }

    updateKedDynamicFields = (serviceDescriptionID,serviceDescriptions,dynamicCapturedData,isExclusion = false,isServiceDescriptionChanged) => {
        let dynamicFields = [];
        let dynamicEntries = [];

        serviceDescriptionID.map(id=>{
        const serviceDescriptionID = id;

        // if serviceDescriptionID is not '' and descriptions and/or exclusions have been populated
        if (serviceDescriptionID &&
            ((serviceDescriptions && serviceDescriptions.descriptions.length > 0) 
             || 
             (serviceDescriptions && serviceDescriptions.exclusions && serviceDescriptions.exclusions.length > 0))) 
            {
            const descList = [...serviceDescriptions.descriptions].concat(...serviceDescriptions.exclusions);

            dynamicFields = descList.find(x => {
                return x.id === parseInt(serviceDescriptionID, 10)
            }).dynamicCaptureFields;

            dynamicFields && dynamicFields.map(x => { 
                x.dynamicDropDownValues && x.dynamicDropDownValues.map(item=>{
                    dynamicEntries.push({ id: x.id, value: null, optional: x.optional, key:item.id, title:item.name ,isGCR: x.disableDropDown == true,validateDate: x.validateDate});
                })
            });
        }

        });
        this.setState({dynamicEntries:dynamicEntries},
        ()=>{
            if(dynamicCapturedData && dynamicCapturedData.length >0)
            {
                let dynamicEntriesUpdated = this.state.dynamicEntries;
                dynamicEntriesUpdated = dynamicEntriesUpdated.map(item=>{
                    const capturedItem = dynamicCapturedData.find(x=>{return x.key == item.key});
                    if(capturedItem)
                    {
                        item.value = capturedItem.value;
                    }
                    else if(!item.isGCR)
                    {
                        item.optional = true;
                    }
                    return item;
                });
                this.setState({dynamicEntries:dynamicEntriesUpdated});
            }
        }
        );
    };
    
    updateDynamicEntries = (dropDownValue,key) =>{
        let dynamicEntry = []; 
        dynamicEntry=  this.state.dynamicEntries.map(x => {
            if(x.key == key)
            {
                x.value = dropDownValue; 
            }
            return x;
        })
        this.setState({dynamicEntries:dynamicEntry},()=>{
            this.sendDynamicCaptureData();
        });
    }

    populateEditFields = (entries) => {
        const dropDownvalues= {};
        const checkBoxValues = {};
        const validatedDates = {};
        entries && entries.map(item=>{
           if(!item.isGCR)
           {
           checkBoxValues[item.id] = true;
           }
           dropDownvalues[item.value.id] = item.value.value;
           if(item.validateDate)
           {
                validatedDates[item.value.id] = {['date']:item.value.value,['title']: item.title}; 
           }
        });
        this.setState(prevState => ({quantitativeDatePickerValues:dropDownvalues,checkBox: checkBoxValues,validationDates:validatedDates}));
    }

    updateDynamicValue = (entry) => {
        this.setState({
            dynamicEntries: {
                ...this.state.dynamicEntries,
                dynamicEntries: this.state.dynamicEntries.map(x => {
                    if (x.id === field.id && x.key == entry.key) {
                        x.value = entry.value;
                    }
                    return x;
                })
            }
        })
    }

    convertDateToString=(date)=>{
        const dateValue = new Date(date);
        return dateValue.toISOString().slice(0,10);
    }

    handleQuantitativeTestDateChange = (dynamicCaptureId,item,date,key,validateDate) => {
        const dropDownValue= {"id":item.id,"value": (date == null? null:this.convertDateToString(date))};

        this.setState(prevState => ({
            quantitativeDatePickerValues: {...this.state.quantitativeDatePickerValues,[item.id]:date} }));

        if(validateDate)
       {
            this.setState(prevState => (
            {validationDates:{...this.state.validationDates,[item.id]:{['date']:date,['title']: item.name} }}));
        }
        this.updateDynamicEntries(dropDownValue,key);
     }

     sendDynamicCaptureData = ()=> {
        let dynamicEntry = []; 
        let validationMessages = [];
       
        validationMessages.push(this.validateCapturedData());

        this.state.dynamicEntries.map(x => {
            if(x.value != null)
            {
                dynamicEntry.push(x);
            }
        })
        this.props.onDataFromChild(dynamicEntry,validationMessages);
     }

     validateCapturedData = ()=>{
        let validationMessages = [];
        let isCheckBoxSelected = false;

        //validate if both the service descriptions are selected
        if(this.state.validationMessage.length >0)
        {
           validationMessages.push(...this.state.validationMessage);
        }

        //Validate if the Radio options are selected
        if(Object.keys(this.state.checkBox).length == 0)
        {
            validationMessages.push({ id: `invalid`, field: '', errorText: `Please select any of the DOS` });
            isCheckBoxSelected = true;
        }

        //Required Field Validation
        this.state.dynamicEntries.flatMap(x => {
            if(!x.optional && (x.value == null || (x.value && x.value.value == null)) )
            {
                validationMessages.push({ id: `invalid ${x.title}`, field: x.title, errorText: `${x.title} is a required field` });
            }
        })

        //Validate if Urine Albumin and Urine Creatinine Test dates are four days or less apart
        if(Object.entries(this.state.validationDates).length > 1)
        {
            validationMessages.push(this.validateDateFields());
        }

        return validationMessages;

     }

     validateDateFields = () => {
        
        const entries=Object.values(this.state.validationDates);
        if(entries[0].date != null && entries[1].date != null ){
            const date1 = new Date(entries[0].date);
            const date2 = new Date(entries[1].date);
            const differenceInMs = Math.abs(date2-date1);
            const diffInDays = differenceInMs /(1000*60*60*24);

            if(diffInDays > 4)
                return { id: `Invalid DOS`, field: 'Invalid DOS', errorText: `${entries[0].title} and ${entries[1].title}  should be 4 days or less apart` } ;
        
            }
        }

    handleRadioOption =(id,e) =>{
        const keyValue= Object.keys(this.state.checkBox).filter(key=>{ return key != id && key != ""});
        const [key1,key2] = keyValue;
        if(key1 && key2)
        {
            this.setState(prevState => ({checkBox: {...this.state.checkBox,[id]:true,[key1]:false,[key2]:false}}),()=>{
                this.sendDynamicCaptureData();
            });
            this.clearUnselectedDOS(key1);
            this.clearUnselectedDOS(key2);
        }
        else if(key1){
            this.setState(prevState => ({checkBox: {...this.state.checkBox,[id]:true,[key1]:false}}),()=>{
                this.sendDynamicCaptureData();
            });
            this.clearUnselectedDOS(key1);
        }
        else
        {
            this.setState(prevState => ({checkBox: {...this.state.checkBox,[id]:true}}),()=>{
                this.sendDynamicCaptureData();
            });
        }

        this.setMandatoryField(id);
       
    }

    setMandatoryField =(dynamicCaptureFieldId)=>{
        let updatedEntries=this.state.dynamicEntries.map((item)=>{
            if(item.id != dynamicCaptureFieldId && !item.isGCR)
            {
                item.optional=true;
            }
            if(item.id ==dynamicCaptureFieldId )
            {
                item.optional=false;
            }
            return item;
        });
        this.setState({dynamicEntries:updatedEntries})
    }

    clearUnselectedDOS = (keyValue)=>{
        const dynamicCaptureId = keyValue;

        const unselectOptions = [];

        this.state.dynamicEntries.map(item=>{
            if(item.id ==  dynamicCaptureId)
            {
                unselectOptions.push(item.key);
            }
        })

        const [key1,key2] = unselectOptions;
   
        if(key2 && key1)
        {
            let updatedEntries=this.state.dynamicEntries.map((item)=>{
                if(item.value && (item.value.id == key1 || item.value.id == key2))
                {
                    item.value=null;
                }
                return item;
            });

            this.setState( ({
                quantitativeDatePickerValues:{...this.state.quantitativeDatePickerValues,[key1]:null,[key2]:null},
                dynamicEntries:updatedEntries
            })
            );
        
        } 
        else if(key1){
            let updatedEntries=this.state.dynamicEntries.map((item)=>{
                if(item.value && item.value.id == key1 )
                {
                    item.value=null;
                }
                return item;
            });

            this.setState( ({
                quantitativeDatePickerValues:{...this.state.quantitativeDatePickerValues,[key1]:null},
                dynamicEntries:updatedEntries
                }));
        }
    }

    renderDropDownValues = (x)=>{
         return(
         <div>
        <Row style={{ marginBottom: '1rem' }}>
            <Col xs={11}>
                <Radio  id={x.id} className="starsRadio"
                        checked={this.state.checkBox[x.id] ?this.state.checkBox[x.id]:false}
                        onChange={(e) => this.handleRadioOption(x.id,e)}>
                        <b>{x.title }</b>
                </Radio>
            </Col>
        </Row> 
        {
            x.dynamicDropDownValues.map(item => {
                return (
                    <Row  style={{ marginBottom: '1rem' }}>
                        <Col xs={6}><ControlLabel style={{ marginRight: '1rem',fontWeight:'normal' }} >{item.name}</ControlLabel></Col>
                        <Col xs={6}><DatePicker key={item.id}  id={item.name} style={{ zIndex: 'auto' }} 
                        onChange={(date)=>this.handleQuantitativeTestDateChange(x.id,item,date,item.id,true,x.validateDate)}
                        value = {this.state.quantitativeDatePickerValues[item.id] ?this.state.quantitativeDatePickerValues[item.id] :'' }
                        disabled= {!this.state.checkBox[x.id] }/></Col>
                    </Row>
                )
            })
            }
        </div>
       );
    }

    renderDynamicCaptureField = (x)=>{
        return(
            <div>
            {
            x.dynamicDropDownValues.map(item => {
                return (
                    <Row  style={{ marginBottom: '1rem' }}>
                        <Col xs={6}><ControlLabel style={{ marginRight: '1rem',fontWeight:'normal' }} >{item.name}</ControlLabel></Col>
                        <Col xs={6}><DatePicker key={item.id}  id={item.name} style={{ zIndex: 'auto' }} 
                        onChange={(date)=>this.handleQuantitativeTestDateChange(x.id,item,date,item.id,false,false)}
                        value = {this.state.quantitativeDatePickerValues[item.id] ?this.state.quantitativeDatePickerValues[item.id] :'' }
                        /></Col>
                    </Row>
                )
            })
            }
        </div>
        );
    }

    clearStateValues = ()=>{
       this.setState({
            checkBox:{},
            quantitativeDatePickerValues:{},
            validationDates:{},
            dynamicEntries: [],
            validationMessage:[],
            validationDates:{}
       });
    }

    componentDidMount()
    {
        const serviceDescriptionID  = this.props.serviceDescriptions.descriptions.map(x => {
            return x.id;});
        this.updateKedDynamicFields(serviceDescriptionID,this.props.serviceDescriptions,this.props.dynamicEntry,this.props.isExclusion);
        if(this.props.dynamicEntry && this.props.dynamicEntry.length>0)
        {
            this.populateEditFields(this.props.dynamicEntry);
        }
    }

    componentDidUpdate(prevProps){
        if(!this.props.isKed){
            this.clearStateValues();
        }
        if(this.props.selectedKedMeasures != prevProps.selectedKedMeasures)
        {
            const selectedMeasures  = this.props.selectedKedMeasures;
            let dynamicCaptureFields = [];
            let dynamicDropDownValues = [];
            this.props.serviceDescriptions.descriptions.map(item=>{
                if(!(selectedMeasures.includes(item.id)))
                {
                   item.dynamicCaptureFields.map(x=>{
                        x.dynamicDropDownValues.map(y=>{
                            dynamicDropDownValues.push(y.id);
                        });
                        dynamicCaptureFields .push(x.id);
                    });
                }
            });

            const [key1,key2] = dynamicCaptureFields;
            const [value1,value2,value3] = dynamicDropDownValues;

            let updatedEntries=this.state.dynamicEntries.map((item)=>{
                if(item.key == value1 || item.key == value2 || item.key == value3)
                {
                    item.value=null;
                }
                return item;
            });

            this.setState( ({
                checkBox: {...this.state.checkBox,[key1]:false,[key2]:false},
                quantitativeDatePickerValues:{...this.state.quantitativeDatePickerValues,[value1]:null,[value2]:null,[value3]:null},
                dynamicEntries:updatedEntries
            })
            );

        }
    }

    componentWillUnmount(){
        this.clearStateValues();
    }
    
    render() {
        const value  = this.props;
        return (
            <div>
                <div>
                { value.serviceDescription && 
                    value.serviceDescription.map(item => {
                        return (
                            (value.selectedKedMeasures.includes(item.id)) && 
                            item.dynamicCaptureFields && item.dynamicCaptureFields.map(x=>{
                                return( 
                                    x.dynamicDropDownValues.length > 0 && x.disableDropDown == false ? 
                                   this.renderDropDownValues(x) : this.renderDynamicCaptureField(x)
                                );
                            }) 
                        )
                    })
                }
                </div>
            </div>
        );
    }
}

export default KedMeasure;