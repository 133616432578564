import React from 'react';
import axios from 'axios';
import { Col, Grid, Row, Glyphicon } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import Error from '../../../Shared/Error';
import Info from '../../../Shared/Info';
import NLPTableImageContainer from './NLPTableImageContainer';
import NLPDiagnosisFormModal from './NLPDiagnosisFormModal';

import { PDFLinkService, PDFFindController, PDFViewer, EventBus } from 'pdfjs-dist/web/pdf_viewer';
import * as pdfjs from 'pdfjs-dist/build/pdf';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import 'pdfjs-dist/web/pdf_viewer.css';
import ReactPopout from '../../../../external/ReactPopout';
import  {PdfViewerHOC}  from './PdfViewerHOC';


class ImageViewerNLPInsights extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nlpInsightData: [],
            shownlpInsightDataError: false,
            medicalRecordImageId: 0,
            imageData: null,
            imageType: null,
            imageLoading: false,
            showImageError: false,
            pageLoaded: false,
            tableCollapsed: true,
            data:null,
            pdfViewer: null,
            isPoppedOut: props.pdfIsPoppedOut,
            handlePaging: props.handlePaging,
            pdfFile:null,
            jumpToPage:0,
            resultsByVersion: [],
            hccProfileError: false,
            defaultHCCVersion: []
        };
        this.lineOfBusiness = localStorage.getItem("lineOfBusiness");
        this.pdfUrlsExisted = [];

    }

    componentDidMount(){
        window.addEventListener('storage', (event) => {
            if (event.storageArea != localStorage) return;
            if (event.key === 'popoutPageNumber') {
              // Do something with event.newValue
              this.navigateToPage(Number(event.newValue));
            }
        });        
        this.getNlpInsights();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.pdfIsPoppedOut !== this.props.pdfIsPoppedOut) {
            this.setState({isPoppedOut: this.props.pdfIsPoppedOut});
            if(!this.props.pdfIsPoppedOut){
                this.getNlpInsights();
            }
        }
      }

    navigateToPage = (pageNum) => {
        console.log('navigateToPage insightstab');
        if(!isNaN(pageNum)){
            if(this.props.pdfIsPoppedOut) {
                if(window.localStorage.getItem('popoutPageNumber')){
                    window.localStorage.removeItem('popoutPageNumber');      
                    window.localStorage.setItem('popoutPageNumber', pageNum);

                } else {
                    window.localStorage.setItem('popoutPageNumber', pageNum);
                }
            }
            else
            {
                //this.state.pdfViewer.currentPageNumber = pageNum;
                this.setState({jumpToPage:pageNum});
            }
        }
    }

    //onPageLoad = () => {
        // const viewerContainer = document.getElementById("viewerContainer");
        // setTimeout(() => {
        //     viewerContainer.scrollTop = 1; // triggers scrollevent to render pdfs
        // }, "1000")
      //};


    toggleWindow = (version) => {
        if(!this.props.pdfIsPoppedOut
            && !this.state.showImageError 
            && this.state.imageData 
            && this.state.imageType === "application/pdf"){
            this.props.togglePdfWindow(this.state.imageData, this.state.imageType);
            axios.get(`/mrrnlpapi/v1/logaudit/${this.props.location.query.medicalRecordReviewID}/${this.props.userID}`, {
                params: {}
            })
            .then((response) => {
                if(response.status === 200){
                }
            })
            .catch((error) => {
                console.log("NLPInsights-displayImage " + error);
                this.setState({
                    showImageError: true,
                    imageLoading: false
                });
            });
        }
    }
    toggleInsightsWindow = () => {
        if(this.state.nlpInsightData){
            this.props.toggleInsightsWindow();
        }
    }
    

    //Get NLP Insigts data via API
    //MRR-24129
    getNlpInsights = () => {        
        Promise.all([
            axios.get(`/memberService/v1/member/${this.props.reviewProcessingUIConfig.memberInternalKey}/diagnosisProfile/MemberHCCDataForNLP`),
            axios.get(`/member/${this.props.reviewProcessingUIConfig.medicalRecordReviewID}/diagtargetbyhccversion`),
            axios.get(`/mrrService/v1/tepReference/defaultHCCVersion`),
            axios.get(`/mrrnlpapi/v1/FilterdMedicalRecordImageInsight/${this.props.location.query.medicalRecordReviewID}`), //new nlp insights data
            axios.get('/securable/ConfigDB', { params: { configKeys: ["NlpIncrementalCodingEnabled"] } })
        ])
            .then((response) => {

                let isIncrementalCoding = response[4].data.nlpIncrementalCodingEnabled;                
                let results = this.GetHccProfileData(response);

                if (response[3].status === 200 && response[3].data.length > 0) {
                    let nlpInsightDataWithoutPaid = [];
                    if (Object.values(results).length && Object.values(response[3].data).length) {
                        Object.values(response[3].data).forEach((nlpinsight, index1) => {
                            Object.values(results).forEach((key2, index1) => {
                                Object.values(key2).forEach((hcc, index1) => {
                                    if (hcc.paid === true &&
                                        nlpinsight.diagnosisCode.trim() === hcc.icd.trim() &&
                                        nlpinsight.hccValue === hcc.hcc.toString() &&
                                        nlpinsight.hccVersion.trim() === hcc.hccVersion.trim() &&
                                        new Date(nlpinsight.dosEnd).getFullYear().toString() === hcc.serviceYear) {
                                        nlpInsightDataWithoutPaid.push(nlpinsight);
                                    }
                                })
                            })
                        })
                    }
                    let nlpInsightDataFinal = [];
                    if (isIncrementalCoding == 'True') {
                        let nlpInsightDataResult = response[3].data.filter(function (nlp) {
                            return !nlpInsightDataWithoutPaid.includes(nlp);
                        });

                        nlpInsightDataFinal = this.GroupingNlpInsightByDx(nlpInsightDataResult);
                    }
                    else {
                        nlpInsightDataFinal = this.GroupingNlpInsightByDx(response[3].data);
                    }
                    this.setState({
                        nlpInsightData: nlpInsightDataFinal,
                        medicalRecordImageId: response[3].data[0].medicalRecordImageId,
                        shownlpInsightDataError: false,
                        pageLoaded: true
                    });
                }
                else {
                    this.setState({
                        shownlpInsightDataError: true,
                        pageLoaded: true
                    });
                }
                if (!this.props.pdfIsPoppedOut) {
                    this.displayImage();
                }
            })
            .catch((error) => {
                console.log("NLPInsights-getNlpInsights " + error);
                this.setState({
                    shownlpInsightDataError: true,
                    pageLoaded: true,
                    hccProfileError: true
                });
            });
    }

    GetHccProfileData = (response) => {
        this.setState({ defaultHCCVersion: response[2].data });

        let hccResults = {};
        response[0].data && Object.keys(response[0].data).forEach((key, index) => {
            hccResults[key] = (response[0].data[key]).map(x => {
                return { ...x, targeted: false, hccKey: x.hccValue };
            });
        });

        let targetedResults = {};
        // add "targeted" property to targeted hccs
        if (response[1].data) {
            Object.keys(response[1].data).forEach((key, index) => {
                targetedResults[key] = (response[1].data[key]).map(x => {
                    return { ...x, targeted: true, hccKey: 't' + x.hccValue };
                });
            });
        }

        //hccResults was previously called "hccResultsByVersion"; targetedResults was previously called "diagTargetByHCCVersion"
        let results = {};
        if (Object.keys(hccResults).length && Object.keys(targetedResults).length) {
            Object.keys(hccResults).forEach((key1, index1) => {
                Object.keys(targetedResults).forEach((key2, index2) => {
                    if (key1 === key2) {
                        results[key1] = ([...hccResults[key1]]).concat([...targetedResults[key2]]);
                    }
                    else if (!results[key1]) {
                        results[key1] = [...hccResults[key1]]
                    }
                    if (!results[key2]) {
                        results[key2] = [...targetedResults[key2]]
                    }
                })
            })
        }
        else if (!Object.keys(hccResults).length) {
            results = { ...targetedResults };
        }
        else {
            results = { ...hccResults };
        }
        results = this.showMAVersions(results);

        this.setState({
            resultsByVersion: results,
            hccProfileError: false
        });
        return results;
    }

    GroupingNlpInsightByDx = (nlpInsightDataResult) => {

        let nlpInsightDataFinal = [];
        Object.values(nlpInsightDataResult).forEach((nlp, index1) => {
            if (nlpInsightDataFinal.filter((x) => (x.dosEnd == nlp.dosEnd &&
                x.dosStart == nlp.dosStart &&
                x.diagnosisCode == nlp.diagnosisCode &&
                x.pageEnd == nlp.pageEnd &&
                x.pageStart == nlp.pageStart &&
                x.hccValue.includes(nlp.hccValue))).length == 0) {
                let arr = nlpInsightDataResult.filter(val => (val.dosEnd == nlp.dosEnd && val.dosStart == nlp.dosStart && val.diagnosisCode == nlp.diagnosisCode && val.pageEnd == nlp.pageEnd && val.pageStart == nlp.pageStart))
                let itemResult = [];
                Object.values(arr).forEach((item, index1) => {
                    if (itemResult.length == 0) {
                        itemResult = item;
                    }
                    else {
                        itemResult.hccValue = itemResult.hccValue + ', ' + item.hccValue;
                        itemResult.hccVersion = itemResult.hccVersion + ', ' + item.hccVersion;
                    }

                })

                nlpInsightDataFinal.push(itemResult);
            }
        })
        return nlpInsightDataFinal;
    }

    showMAVersions = (results) => {
        if (this.lineOfBusiness === "MA") {
            let filteredResults = [];
            if (this.state.defaultHCCVersion.hccVersions && this.state.defaultHCCVersion.hccVersions.length > 0) {
                this.state.defaultHCCVersion.hccVersions.forEach((key1, index1) => {
                    Object.keys(results).forEach((key2, index2) => {
                        if (key1 === key2) {
                            filteredResults[key2] = results[key2];
                        }
                    })
                });
            }
            return this.hccVersionSort(filteredResults);
        }
        else {
            return results;
        }
    }

    hccVersionSort = (result) => {
        const sortedKeys = Object.keys(result).sort().reverse();
        const sortedObject = {};
        sortedKeys.forEach((key) => {
            sortedObject[key] = result[key];
        });
        return sortedObject;
    }

    //Get Image as a byte arry via API
    displayImage = () => {
        this.setState({ imageLoading: true });
    
        axios.get(`/medicalrecordreview/${this.props.location.query.medicalRecordReviewID}/imageNLP`, {
            params: {},
            responseType: 'arraybuffer'
        })
        .then((response) => {
            if(response.status === 200){
                const imageType = "application/pdf";
                const fileUrl = URL.createObjectURL(new Blob([response.data], { type: imageType } ))
                const imageId = this.state.medicalRecordImageId;

                if(response.data != null){
                    this.setState({pdfFile:fileUrl});
                }


                this.setState({
                    imageData: fileUrl,
                    showImageError: false,
                    imageLoading: false,
                    imageType: imageType,
                    data: response.data
                }, () => {
                    this.initialViewer(fileUrl);
                    this.pdfUrlsExisted.push({"imageId": imageId, "fileUrl": fileUrl});
                }
                );
            }
            else{
                this.setState({
                    showImageError: true,
                    imageLoading: false
                });
            }
        })
        .catch((error) => {
            console.log("NLPInsights-displayImage " + error);
            this.setState({
                showImageError: true,
                imageLoading: false
            });
        });
    }


    initialViewer = (url) => {

        const linkService = new PDFLinkService();
        const eventBus = new EventBus();
        const findController = new PDFFindController({linkService, eventBus});

        // const viewerContainer = document.getElementById("viewerContainer");
        // const newViewer = new PDFViewer({
        //     container: viewerContainer,
        //     linkService,
        //     useOnlyCssZoom: true,
        //     textLayerMode: 1,
        //     findController,
        //     eventBus
        // });
        // linkService.setViewer(newViewer);

        // newViewer.currentScaleValue = 1.5;


        const loadingTask = pdfjs.getDocument({ url });
        loadingTask.promise.then(pdf => {
            if(pdf){
                const nums = pdf.numPages
                //newViewer.setDocument(pdf);
                linkService.setDocument(pdf);
                //this.setState({ pdfViewer:newViewer });
            }
        });
    }
   
    collapseTable = () => {
        this.setState({ tableCollapsed: !this.state.tableCollapsed });
    }

    
    getDiagnosisData = () => {
        return axios.get(`/medicalRecordReview/${this.props.reviewProcessingUIConfig.medicalRecordReviewID}/medicalRecordDiag/`)
        .then((response) => {
            this.setState({
                diagnosisData: response.data,                
            }, () => {
                this.props.setTriggerDiagRefresh(true);
                this.props.setDiagIDList(response.data.map(x => x.medicalRecordDiagID));
                this.props.setClaimsDiagInitiatorIDList(response.data.map(x => {
                    return {
                        initiatorIdentifier: x.initiatorIdentifier,
                        diagnosisCD: x.diagnosisCD
                    }
                }));
            });
            if (response.data.length > 0) {
                this.props.setReviewHasAddedRecords();
            }
        })
        .catch((error) => {
            //this.props.toast('error', 'Could not load diagnosis data.', 'Error');
        });
    }

    getDiagnosisHistoryData = () => {
        return axios.get(`/member/${this.props.reviewProcessingUIConfig.memberInternalKey}/diaghistory`)
        .then((response) => {
            this.setState({ diagnosisHistoryData: response.data });
        })
        .catch((error) => {
            //this.props.toast('error', 'Could not load diagnosis history data.', 'Error');
        })
    }

    render() {
        console.log("pageLoaded: " + this.state.pageLoaded); 
        console.log("pdfjs-version"); console.log(pdfjs.version);

        const options = {
            toolbar: 'no',
            location: 'no',
            directories: 'no',
            status: 'no',
            menubar: 'no',
            scrollbars: 'yes',
            resizable: 'yes',
            width: window.screen.width * .8,
            height: window.screen.height * .8,
            top: (o, w) => (w.innerHeight - o.height) / 2 + w.screenY,
            left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX
        };

        return (
            <Grid fluid>
                <Loader loaded={this.state.pageLoaded}>               
                    <Row style={{height:'600px'}}>
                        <Col xs={this.state.tableCollapsed ? 6 : 6} style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                            <div style={{ maxHeight: `600px`, overflowY: 'scroll', paddingLeft: '15px', paddingRight: '30px' }}>     
                                <section>
                                    {
                                        this.state.shownlpInsightDataError && <Error errorType = 'noData' style={{ marginTop: '5rem' }} />
                                    }
                                    {
                                        !this.state.shownlpInsightDataError && !this.props.insightsIsPoppedOut &&
                                        <NLPTableImageContainer key={this.state.medicalRecordImageId}
                                            title={"NLP Insights Data"} nlpInsightData={this.state.nlpInsightData} 
                                            navigateToPage={this.navigateToPage}
                                            tableCollapsed={this.state.tableCollapsed} collapseTable={this.collapseTable} displayCollapseButton={true}
                                            toast={this.props.toast} medicalRecordTypeData={this.props.medicalRecordTypeData} sourceData={this.props.sourceData} 
                                            providerTypeData={this.props.providerTypeData} rafTypeData={this.props.rafTypeData}
                                            memberID={this.props.reviewProcessingUIConfig.memberInternalKey}
                                            reviewProcessingUIConfig={this.props.reviewProcessingUIConfig}
                                            medicalRecordTypeID={this.props.medicalRecordTypeID}
                                            diagnosisComments={this.props.diagnosisComments}
                                            reloadImages={this.props.reloadImages} imageRecords={this.props.imageRecords}
                                            userID={this.props.userID} getDiagnosisData={this.getDiagnosisData} 
                                            getDiagnosisHistoryData={this.getDiagnosisHistoryData} setDiagFormData={this.props.setDiagFormData}
                                            reviewType={this.props.reviewType} isInsightsPoppedOut={this.state.isInsightsPoppedOut}
                                            toggleInsightsWindow={this.toggleInsightsWindow} handlePaging={this.handlePaging}
                                        />
                                    }
                                </section>
                            </div>
                        </Col>
                        <Col xs={this.state.tableCollapsed ? 6 : 6} style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                            {
                                !this.state.isPoppedOut &&
                                <div style={{ maxHeight: `800px`, paddingLeft: '15px', paddingRight: '30px' }}>
                                    <span style={{ display: (this.state.showImageError || this.state.isPoppedOut) ? 'none' : 'initial', paddingRight: '1rem', cursor: 'pointer'}}>
                                        <Glyphicon glyph="new-window" onClick={this.toggleWindow} style={{ zIndex:'1'}}/>
                                    </span>
                                    <Loader loaded={!this.state.imageLoading && !this.state.isPoppedOut}>
                                        {
                                            !this.state.showImageError && this.state.imageData && this.state.imageType === "text/html" &&
                                            <embed width="100%" src={this.state.imageData} type={this.state.imageType} />
                                        }
                                        {
                                            !this.state.showImageError && this.state.imageData && this.state.imageType === "application/pdf" && 

                                            //style: height needed for PDF page navigation
                                            <div  style={{ height:`550px`, marginLeft :`20px`, marginTop:`-15px`}}>   
                                              <PdfViewerHOC fileUrl={this.state.pdfFile} jumpToPage={this.state.jumpToPage} ></PdfViewerHOC>
                                            </div>
                                        }
                                        {
                                            this.state.showImageError &&
                                            <Error errorType = 'noImage' style={{ marginTop: '5rem' }} />
                                        }
                                    </Loader>
                                </div>
                            }
                        </Col>
                    </Row>
                </Loader>
            </Grid>
        );
    }
}

export default ImageViewerNLPInsights;