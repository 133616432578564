import React from 'react';
import axios from 'axios';
import { Col, Grid, Row, Glyphicon, DropdownButton,Tabs, Tab, MenuItem } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loader from 'react-loader';
import Error from '../../../Shared/Error';
import Info from '../../../Shared/Info';
import NLPInsightHCCProfileContainer from '../NLPInsightsByHCC/NLPInsightHCCProfileContainer';

import { PDFLinkService, PDFFindController, PDFViewer, EventBus } from 'pdfjs-dist/web/pdf_viewer';
import * as pdfjs from 'pdfjs-dist/build/pdf';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import 'pdfjs-dist/web/pdf_viewer.css';
import ReactPopout from '../../../../external/ReactPopout';
import  {PdfViewerHOC}  from './PdfViewerHOC';


class ImageViewerNLPInsightsByHCC extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nlpInsightData: [],
            shownlpInsightDataError: false,
            hccVersionData: [],
            hccVersionDataError: false,
            selectedHCCVersion: null,
            medicalRecordImageId: 0,
            imageData: null,
            imageType: null,
            imageLoading: false,
            showImageError: false,
            pageLoaded: false,
            tableCollapsed: true,
            insightsHccIsPoppedOut: false,
            data:null,
            pdfViewer: null,
            isPoppedOut: props.pdfIsPoppedOut,
            handlePaging: props.handlePaging,
            pdfFile:null,
            jumpToPage:0
        };

        this.pdfUrlsExisted = [];

    }

    componentDidMount(){
        window.addEventListener('storage', (event) => {
            if (event.storageArea != localStorage) return;
            if (event.key === 'popoutPageNumber') {
              // Do something with event.newValue
              this.navigateToPage(Number(event.newValue));
            }
        }); 
        this.getHCCVersions();  
        this.getNlpInsights(false);

    }

    componentDidUpdate(prevProps) {
        if (prevProps.pdfIsPoppedOut !== this.props.pdfIsPoppedOut) {
            this.setState({isPoppedOut: this.props.pdfIsPoppedOut});
            if(!this.props.pdfIsPoppedOut){
                this.getNlpInsights(false);
            }
        }
      }

    navigateToPage = (pageNum) => {
        console.log('navigateToPage insightstab');
        if(!isNaN(pageNum)){
            if(this.props.pdfIsPoppedOut) {
                if(window.localStorage.getItem('popoutPageNumber')){
                    window.localStorage.removeItem('popoutPageNumber');      
                    window.localStorage.setItem('popoutPageNumber', pageNum);

                } else {
                    window.localStorage.setItem('popoutPageNumber', pageNum);
                }
            }
            else
            {
                //this.state.pdfViewer.currentPageNumber = pageNum;
                this.setState({jumpToPage:pageNum});
            }
        }
    }

    //onPageLoad = () => {
        // const viewerContainer = document.getElementById("viewerContainer");
        // setTimeout(() => {
        //     viewerContainer.scrollTop = 1; // triggers scrollevent to render pdfs
        // }, "1000")
      //};


    toggleWindow = (version) => {
        if(!this.props.pdfIsPoppedOut
            && !this.state.showImageError 
            && this.state.imageData 
            && this.state.imageType === "application/pdf"){
            this.props.togglePdfWindow(this.state.imageData, this.state.imageType);

            axios.get(`/mrrnlpapi/v1/logaudit/${this.props.location.query.medicalRecordReviewID}/${this.props.userID}`, {
                params: {}
            })
            .then((response) => {
                if(response.status === 200){
                }
            })
            .catch((error) => {
                console.log("NLPInsights-displayImage " + error);
                this.setState({
                    showImageError: true,
                    imageLoading: false
                });
            });
        }
    }
    toggleInsightsHccWindow = () => {
        if(this.state.nlpInsightData){
            this.props.toggleInsightsHccWindow();
        }
    }
    //Get NLP Insigts data via API
    //NLP-31
    getHCCVersions = () => {
        axios.get(`/mrrnlpapi/v1/MedicalRecordImages/HCCVersion`)
        .then((response) => {    
            if(response.status === 200 && response.data.length > 0){  
                this.setState({                        
                    hccVersionData: response.data.sort((a,b) => a.item > b.item ?1:-1),
                    hccVersionDataError: false,
                    pageLoaded: true
                });

         const lineOfBusiness = localStorage.getItem('lineOfBusiness');
        if(lineOfBusiness=="MA" && this.state.hccVersionData.length > 0)
        {
            this.setState({selectedHCCVersion : this.state.hccVersionData.some( item => item.toUpperCase()=='V28') ? 'V28': 
            (this.state.hccVersionData.some( item => item.toUpperCase()=='V24') ? 'V24': this.state.hccVersionData[0] ) });
        }
        else{
            this.setState({selectedHCCVersion : this.state.hccVersionData.some( item => item.toUpperCase()=='V08') ? 'V08': this.state.hccVersionData[0]});
        }
            }
            else{
                this.setState({
                    hccVersionDataError: true,
                    pageLoaded: true
                });
            }
           
        
        
        })
        .catch((error) => {
            console.log("NLPInsightsByHCC-getHCCVersions " + error);
            this.setState({
                hccVersionDataError: true,
                pageLoaded: true
            });
        });
    }

    handleSelectHCCVersion = (hccVersion) => {
        this.setState({ selectedHCCVersion: hccVersion });
        this.getNlpInsights(true);
        this.renderResults();
    }

    //Get NLP Insigts data via API
    //MRR-24129
    getNlpInsights = (reload) => {
        Promise.all([
        axios.get(`/mrrnlpapi/v1/FilterdMedicalRecordImageInsightByHcc/${this.props.location.query.medicalRecordReviewID}`) //new nlp insights data
        ])
        .then((response) => { 
            let nlpInsightDataFilter = [];
            let hccResults=[];
            response[0].data && Object.keys(response[0].data).forEach((key, index) => {
                let version=  response[0].data[key].hccVersion!=null ? response[0].data[key].hccVersion.split(',') :null;
                {version!=null && version.sort((a,b) => a.item > b.item ?1:-1).map((item) => 
                {
                    if(item!=null && !hccResults.some(x=> x.item.includes(item)))
                    {
                        hccResults.push({item});
                    } 
                })}});
                    hccResults = this.showMAVersions(hccResults, reload);
                
                if(response[0].status === 200 && response[0].data.length > 0){ 
                    
                    Object.keys(response[0].data).forEach((key, index) => {                        
                        if(this.state.selectedHCCVersion && response[0].data[key].hccVersion!=null 
                            && response[0].data[key].hccVersion.toUpperCase().includes(this.state.selectedHCCVersion.toUpperCase().trim())) 
                            {
                                    nlpInsightDataFilter[index]=(response[0].data[key]);
                            }
                        
                    });                    
                    
                    this.setState({                        
                        nlpInsightData: nlpInsightDataFilter,
                        medicalRecordImageId: response[0].data ==null ? null : response[0].data[0].medicalRecordImageID,
                        shownlpInsightDataError: false,
                        pageLoaded: true
                    });
                }
                else{
                    this.setState({
                        shownlpInsightDataError: true,
                        pageLoaded: true
                    });
                }   
                this.setState({
                    resultsByVersion: hccResults,
                    pageLoaded: true,
                    hccProfileError: false
                     
                });
                if(!this.props.pdfIsPoppedOut){
                    this.displayImage();
                }
        })
        .catch((error) => {
            console.log("NLPInsights-getNlpInsights " + error);
            this.setState({
                shownlpInsightDataError: true,
                pageLoaded: true
            });
        });        
    }

    getDefaultTab = (results) => {
        if (this.state.defaultHCCVersion) {
            let hccVersion = this.state.defaultHCCVersion;
            for (let i = 0; i < hccVersion.length; i++) {
                if (Object.keys(results).some(key1 => key1 === hccVersion[i])) {
                    return Object.keys(results).indexOf(hccVersion[i]);
                }
            }
        }
        return 0;
    }

    showMAVersions = (results, reload) => {
        const lineOfBusiness = localStorage.getItem('lineOfBusiness');
        let filteredResults = [];
        if(lineOfBusiness === "MA"){            
            if(this.state.hccVersionData && this.state.hccVersionData.length > 0 && results.length > 0){
                this.state.hccVersionData.forEach((key1, index1) => {
                    Object.values(results).forEach((key2, index2) => {
                        if(key1.toUpperCase().trim() === key2.item.toUpperCase().trim()){
                            filteredResults.push(key2.item);
                        }
                    })
                });
            }
            if(!reload)
            this.setState({selectedHCCVersion :filteredResults[0]});

            return filteredResults;
        }
        else {
            if(this.state.hccVersionData && this.state.hccVersionData.length > 0)
            {
                this.state.hccVersionData.forEach((key1, index1) => {                    
                            filteredResults.push(key1);
                        });
            }
            return filteredResults;
        }
    }

    //Get Image as a byte arry via API
    displayImage = () => {
        this.setState({ imageLoading: true });
    
        axios.get(`/medicalrecordreview/${this.props.location.query.medicalRecordReviewID}/imageNLP`, {
            params: {},
            responseType: 'arraybuffer'
        })
        .then((response) => {
            if(response.status === 200){
                const imageType = "application/pdf";
                const fileUrl = URL.createObjectURL(new Blob([response.data], { type: imageType } ))
                const imageId = this.state.medicalRecordImageId;

                if(response.data != null){
                    this.setState({pdfFile:fileUrl});
                }


                this.setState({
                    imageData: fileUrl,
                    showImageError: false,
                    imageLoading: false,
                    imageType: imageType,
                    data: response.data
                }, () => {
                    this.initialViewer(fileUrl);
                    this.pdfUrlsExisted.push({"imageId": imageId, "fileUrl": fileUrl});
                }
                );
            }
            else{
                this.setState({
                    showImageError: true,
                    imageLoading: false
                });
            }
        })
        .catch((error) => {
            console.log("NLPInsights-displayImage " + error);
            this.setState({
                showImageError: true,
                imageLoading: false
            });
        });
    }


    initialViewer = (url) => {

        const linkService = new PDFLinkService();
        const eventBus = new EventBus();
        const findController = new PDFFindController({linkService, eventBus});

        // const viewerContainer = document.getElementById("viewerContainer");
        // const newViewer = new PDFViewer({
        //     container: viewerContainer,
        //     linkService,
        //     useOnlyCssZoom: true,
        //     textLayerMode: 1,
        //     findController,
        //     eventBus
        // });
        // linkService.setViewer(newViewer);

        // newViewer.currentScaleValue = 1.5;


        const loadingTask = pdfjs.getDocument({ url });
        loadingTask.promise.then(pdf => {
            if(pdf){
                const nums = pdf.numPages
                //newViewer.setDocument(pdf);
                linkService.setDocument(pdf);
                //this.setState({ pdfViewer:newViewer });
            }
        });
    }
   
    collapseTable = () => {
        this.setState({ tableCollapsed: !this.state.tableCollapsed });
    }

    selectTab = (eventKey) => {
        this.setState({ selectedTab: eventKey });
    }

    
    getDiagnosisData = () => {
        return axios.get(`/medicalRecordReview/${this.props.reviewProcessingUIConfig.medicalRecordReviewID}/medicalRecordDiag/`)
        .then((response) => {
            this.setState({
                diagnosisData: response.data,
            }, () => {
                this.props.setDiagIDList(response.data.map(x => x.medicalRecordDiagID));
                this.props.setClaimsDiagInitiatorIDList(response.data.map(x => {
                    return {
                        initiatorIdentifier: x.initiatorIdentifier,
                        diagnosisCD: x.diagnosisCD
                    }
                }));
            });
            if (response.data.length > 0) {
                this.props.setReviewHasAddedRecords();
            }
        })
        .catch((error) => {
            //this.props.toast('error', 'Could not load diagnosis data.', 'Error');
        });
    }

    getDiagnosisHistoryData = () => {
        return axios.get(`/member/${this.props.reviewProcessingUIConfig.memberInternalKey}/diaghistory`)
        .then((response) => {
            this.setState({ diagnosisHistoryData: response.data });
        })
        .catch((error) => {
            //this.props.toast('error', 'Could not load diagnosis history data.', 'Error');
        })
    }
    
    renderResults = () => {
        
        return (
            <div style={{ maxHeight: `600px`, overflowY: 'scroll', paddingLeft: '15px', paddingRight: '30px' }}>     
                                <section>
                                    {
                                        this.state.shownlpInsightDataError && <Info infoType = 'noData' style={{ marginTop: '5rem' }} />
                                    }
                                    {
                                        !this.state.shownlpInsightDataError && !this.props.insightsHccIsPoppedOut &&
                                        <NLPInsightHCCProfileContainer key={this.state.medicalRecordImageId}
                                            title={"NLP Insights Data"} nlpInsightData={this.state.nlpInsightData}
                                            navigateToPage={this.navigateToPage}
                                            tableCollapsed={this.state.tableCollapsed} collapseTable={this.collapseTable} displayCollapseButton={true}
                                            toast={this.props.toast} medicalRecordTypeData={this.props.medicalRecordTypeData} sourceData={this.props.sourceData} 
                                            providerTypeData={this.props.providerTypeData} rafTypeData={this.props.rafTypeData}
                                            memberID={this.props.reviewProcessingUIConfig.memberInternalKey}
                                            reviewProcessingUIConfig={this.props.reviewProcessingUIConfig}
                                            medicalRecordTypeID={this.props.medicalRecordTypeID}
                                            diagnosisComments={this.props.diagnosisComments}
                                            reloadImages={this.props.reloadImages} imageRecords={this.props.imageRecords}
                                            userID={this.props.userID} getDiagnosisData={this.getDiagnosisData} 
                                            getDiagnosisHistoryData={this.getDiagnosisHistoryData} setDiagFormData={this.props.setDiagFormData}
                                            reviewType={this.props.reviewType} isInsightsPoppedOut={false}
                                            toggleInsightsHccWindow={this.toggleInsightsHccWindow} handlePaging={this.handlePaging}
                                        />
                                    }
                                </section>
                            </div>
        )
    }

    render() {
        const dropdownTitle = (this.state.resultsByVersion !=null && [this.state.selectedHCCVersion][0] !=null)  ? [this.state.selectedHCCVersion][0].toUpperCase() : "";
        console.log("pageLoaded: " + this.state.pageLoaded); 
        console.log("pdfjs-version"); console.log(pdfjs.version);

        const options = {
            toolbar: 'no',
            location: 'no',
            directories: 'no',
            status: 'no',
            menubar: 'no',
            scrollbars: 'yes',
            resizable: 'yes',
            width: window.screen.width * .8,
            height: window.screen.height * .8,
            top: (o, w) => (w.innerHeight - o.height) / 2 + w.screenY,
            left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX
        };

        return (
            <Grid fluid>
                <Loader loaded={this.state.pageLoaded}>               
                <Row style={{height:'600px'}}>
                    <Col xs={this.state.tableCollapsed ? 6 : 6} style={{ paddingRight: '20px', paddingLeft: '0px' }}>
                    
                        <Row>
                            <Col xs={12} style={{ paddingRight: '5rem', paddingLeft: '0px' }}>                                
                            <div style={{ paddingTop: '1.2rem', paddingBottom: '1.2rem',display: "inline-block", float: "right"}}>
                            {
                            this.state.resultsByVersion && Object.keys(this.state.resultsByVersion).length > 0 &&
                            <DropdownButton bsStyle="default" bsSize="xsmall" title={dropdownTitle} id="hccVersionDropdown" style={{ marginLeft: '1rem', fontWeight: 'bold' }}>
                                <MenuItem header>HCC Version</MenuItem>
                               {
                                  Object.keys(this.state.resultsByVersion).map((key, index) => {
                                      return this.state.resultsByVersion[key] ? (
                                          <MenuItem key={index} eventKey={index}  onSelect={() => this.handleSelectHCCVersion(this.state.resultsByVersion[key])}>{this.state.resultsByVersion[index].toUpperCase()}</MenuItem>
                                      ) : null
                                  })
                              }
                            </DropdownButton>
                        }
                            </div>
                            </Col>
                        </Row>
                        <Row style={{}}>
                            <Col xs={12} style={{ paddingRight: '0px', paddingLeft: '0px' }}>     
                            
                            <Tabs defaultActiveKey={0} activeKey={this.state.selectedTab} onSelect={this.selectTab} id="hccResultsTabs">
                        {
                            !this.state.hccVersionDataError &&  this.state.resultsByVersion &&
                            this.renderResults()
                        }
                        {
                            //this.state.hccVersionDataError && this.renderEmptyTable()
                        }
                    </Tabs>


                            </Col>
                        </Row>
                    </Col>                    
                    <Col xs={this.state.tableCollapsed ? 6 : 6} style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                                {
                                    !this.state.isPoppedOut &&
                                    <div style={{ maxHeight: `800px`, paddingLeft: '15px', paddingRight: '30px' }}>
                                        <span style={{ display: (this.state.showImageError || this.state.isPoppedOut) ? 'none' : 'initial', paddingRight: '1rem', cursor: 'pointer'}}>
                                            <Glyphicon glyph="new-window" onClick={this.toggleWindow} style={{ zIndex:'1'}}/>
                                        </span>
                                        <Loader loaded={!this.state.imageLoading && !this.state.isPoppedOut}>
                                            {
                                                !this.state.showImageError && this.state.imageData && this.state.imageType === "text/html" &&
                                                <embed width="100%" src={this.state.imageData} type={this.state.imageType} />
                                            }
                                            {
                                                !this.state.showImageError && this.state.imageData && this.state.imageType === "application/pdf" && 

                                                //style: height needed for PDF page navigation
                                                <div  style={{ height:`550px`, marginLeft :`20px`, marginTop:`-15px`}}>   
                                                <PdfViewerHOC fileUrl={this.state.pdfFile} jumpToPage={this.state.jumpToPage} ></PdfViewerHOC>
                                                </div>
                                            }
                                            {
                                                this.state.showImageError &&
                                                <Error errorType = 'noImage' style={{ marginTop: '5rem' }} />
                                            }
                                        </Loader>
                                    </div>
                                }
                    </Col>
                </Row>
                </Loader>
            </Grid>
        );
   }
}

export default ImageViewerNLPInsightsByHCC;