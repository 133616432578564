import React from 'react';
import axios from 'axios';
import { Grid, Tabs, Tab } from 'react-bootstrap';
import Loader from 'react-loader';
import AlertManagement from './AlertManagement';
import CommentConfiguration from './CommentConfiguration';
import CommentDefaultGroup from './CommentDefaultGroup';
import ProjectManagement from './ProjectManagement';
import AlertToastr from '../../Shared/AlertToastr';
import { Securable } from '../../../enums/Securable';
import { SecurableType } from '../../../enums/SecurableType';
import SupplementalFile from './SupplementalFile';

class AdminContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
            loadedTabs: [1],
            landingSecurables: [],
            loaded: false,
            toast: null
        };

        this.lineOfBusiness = localStorage.getItem('lineOfBusiness');
    }

    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    componentWillMount() {
        this.getSecurables()
        .then((response) => {
            this.setState({
                landingSecurables: response.data,
                loaded: true
            });
        })
        .catch((error) => {
            this.setState({ loaded: true });
            this.state.toast('error', 'Could not load admin data', 'Error');
        });
    }

    getSecurables = () => {
        return axios.get('/securable', {
            params: {
                securableTypes: [SecurableType.MRRLandingPageTab]
            }
        });
    }

    handleSelect = (eventKey) => {
        if (this.state.loadedTabs.includes(eventKey)) {
            this.setState({ activeTab: eventKey });
        }
        else {
            this.setState({
                activeTab: eventKey,
                loadedTabs: [...this.state.loadedTabs, eventKey]
            });
        }
    }

    render() {
        return (
            <Grid fluid style={{ marginTop: '2rem' }}>
                <Loader loaded={this.state.loaded}>
                    <Tabs id="adminLandingTabs" onSelect={this.handleSelect} defaultActiveKey={1}
                        style={{ marginTop: '2rem' }}>
                        <Tab eventKey={1} title="Project">
                        {
                            this.state.loadedTabs.includes(1) &&
                            <ProjectManagement toast={this.state.toast} />
                        }
                        </Tab>
                        <Tab eventKey={2} title="Comments">
                        {
                            this.state.loadedTabs.includes(2) &&
                            <CommentConfiguration />
                        }
                        </Tab>
                        <Tab eventKey={3} title="Comment Default Groups">
                        {
                            this.state.loadedTabs.includes(3) &&
                            <CommentDefaultGroup />
                        }
                        </Tab>
                        {
                            this.state.landingSecurables &&
                            this.state.landingSecurables.find(x => x.securableID === Securable.AlertManagement) ?
                            <Tab eventKey={4} title="Alert Management">
                                {
                                    this.state.loadedTabs.includes(4) &&
                                    <AlertManagement />
                                }
                            </Tab> : null
                        }
                        {
                            this.lineOfBusiness === 'Commercial' &&
                            <Tab eventKey={5} title="Supplemental File">
                                {
                                    this.state.loadedTabs.includes(5) &&
                                    <SupplementalFile toast={this.state.toast} />
                                }
                            </Tab>
                        }
                    </Tabs>
                </Loader>
                <AlertToastr setAddToast={this.setAddToast} />
            </Grid>
        );
    }
}

export default AdminContainer;