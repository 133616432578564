import React, { Component } from 'react';
import axios from 'axios';
import Loader from 'react-loader';
import NLPTableImage from './NLPTableImage';

class NLPTableImageNewWindow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nlpInsightData: [],
            shownlpInsightDataError: false,
            medicalRecordImageId: 0,
            pageLoaded: false,
            tableCollapsed: true,
            data:null,
            isImagePoppedOut: false,
            isInsightsPoppedOut: false,
            expanded: true,
            reviewProcessingUIConfig: null,
            toast: null,
            medicalRecordTypeData: null,
            sourceData: null,
            providerTypeData: null,
            rafTypeData: null,
            medicalRecordTypeID: 0,
            reviewCurrentUserID: 0,
            imageRecords: [],
            memberID: 0,
            reviewHasAddedRecords: false,
            resultsByVersion: [],
            hccProfileError: false,
            defaultHCCVersion: []
        };
        this.lineOfBusiness = localStorage.getItem("lineOfBusiness");
    }

    componentWillMount() {
        axios.get(`/ReviewProcessingUIConfig/${this.props.params.reviewType}`, {
            params: {
                medicalRecordReviewID: this.props.location.query.medicalRecordReviewID,
                isCDIQueue: this.props.location.query.isCDIQueue || false
            }
        })
        .then((response) => {
            this.setState({
                reviewProcessingUIConfig: response.data,
                memberID: response.data.memberInternalKey,
                medicalRecordTypeID: response.data.medicalRecordTypeID,
                reviewCurrentUserID: response.data.userID,
                pecCorrectionCommentSelected: response.data.comments && response.data.comments.find(x => x.id === '16' && x.isChecked === true)
            }, () => {
                this.callPromises();
            });
        })
        .catch((error) => {
            this.setState({ pageLoaded: true });
        });
    }

    callPromises(){
        const promises = [this.getReferenceData(), this.getNlpInsights(), this.populateImageIDOptions(), 
            this.getDiagnosisData(), this.getDiagnosisHistoryData()];
            //this.populateRejectionReasons(), this.getCommentGroupOptions(), this.getMRRProviderNPIs(), this.getMemberDemographic()];

        Promise.allSettled(promises)
        .then(([result1, result2, result3, result4, result5]) => {
            console.log(result1, result2, result3, result4, result5)
            this.setState({ pageLoaded: true });
        })
        .catch((error) => {
            console.log('call promises error');
            this.setState({ loaded: true });
            //this.props.toast('error', 'Could not load capture data.', 'Error');
        })
    }

    getDiagnosisHistoryData = () => {
        return axios.get(`/member/${this.state.memberID}/diaghistory`)
        .then((response) => {
            this.setState({ diagnosisHistoryData: response.data });
        })
        .catch((error) => {
            //this.props.toast('error', 'Could not load diagnosis history data.', 'Error');
        })
    }

    getDiagnosisData = () => {
        return axios.get(`/medicalRecordReview/${this.state.reviewProcessingUIConfig.medicalRecordReviewID}/medicalRecordDiag/`)
        .then((response) => {
            this.setState({
                diagnosisData: response.data,
            }, () => {
                this.props.setDiagIDList(response.data.map(x => x.medicalRecordDiagID));
                this.props.setClaimsDiagInitiatorIDList(response.data.map(x => {
                    return {
                        initiatorIdentifier: x.initiatorIdentifier,
                        diagnosisCD: x.diagnosisCD
                    }
                }));
            });
            if (response.data.length > 0) {
                this.setReviewHasAddedRecords();
            }
        })
        .catch((error) => {
            //this.props.toast('error', 'Could not load diagnosis data.', 'Error');
        });
    }

    setReviewHasAddedRecords = () => {
        this.setState({ reviewHasAddedRecords: true });
    }

    getNlpInsights = () => {
        Promise.all([
            axios.get(`/memberService/v1/member/${this.state.memberID}/diagnosisProfile/MemberHCCDataForNLP`),
            axios.get(`/member/${this.props.location.query.medicalRecordReviewID}/diagtargetbyhccversion`),
            axios.get(`/mrrService/v1/tepReference/defaultHCCVersion`),
            axios.get(`/mrrnlpapi/v1/FilterdMedicalRecordImageInsight/${this.props.location.query.medicalRecordReviewID}`), //new nlp insights data
            axios.get('/securable/ConfigDB', { params: { configKeys: ["NlpIncrementalCodingEnabled"] } })
        ])
            .then((response) => {
                let isIncrementalCoding = response[4].data.nlpIncrementalCodingEnabled;                
                let results = this.GetHccProfileData(response);

                if (response[3].status === 200 && response[3].data.length > 0) {
                    let nlpInsightDataWithoutPaid = [];
                    if (Object.values(results).length && Object.values(response[3].data).length) {
                        Object.values(response[3].data).forEach((nlpinsight, index1) => {
                            Object.values(results).forEach((key2, index1) => {
                                Object.values(key2).forEach((hcc, index1) => {
                                    if (hcc.paid === true &&
                                        nlpinsight.diagnosisCode.trim() === hcc.icd.trim() &&
                                        nlpinsight.hccValue.includes(hcc.hcc) &&
                                        nlpinsight.hccVersion.includes(hcc.hccVersion) &&
                                        new Date(nlpinsight.dosEnd).getFullYear().toString() === hcc.serviceYear) {
                                        nlpInsightDataWithoutPaid.push(nlpinsight);
                                    }
                                })
                            })
                        })
                    }
                    let nlpInsightDataFinal = [];
                    if (isIncrementalCoding == 'True') {
                        let nlpInsightDataResult = response[3].data.filter(function (nlp) {
                            return !nlpInsightDataWithoutPaid.includes(nlp);
                        });

                        nlpInsightDataFinal = this.GroupingNlpInsightByDx(nlpInsightDataResult);
                    }
                    else {
                        nlpInsightDataFinal = this.GroupingNlpInsightByDx(response[3].data);
                    }

                    this.setState({
                        nlpInsightData: nlpInsightDataFinal,
                        medicalRecordImageId: response[3].data[0].medicalRecordImageId,
                        shownlpInsightDataError: false,
                        pageLoaded: true
                    });
                }
                else {
                    this.setState({
                        shownlpInsightDataError: true,
                        pageLoaded: true
                    });
                }
            })
            .catch((error) => {
                console.log("NLPInsights-getNlpInsights " + error);
                this.setState({
                    shownlpInsightDataError: true,
                    pageLoaded: true,
                    hccProfileError: true
                });
            });
    }

    GetHccProfileData = (response) => {
        this.setState({ defaultHCCVersion: response[2].data });

        let hccResults = {};
        response[0].data && Object.keys(response[0].data).forEach((key, index) => {
            hccResults[key] = (response[0].data[key]).map(x => {
                return { ...x, targeted: false, hccKey: x.hccValue };
            });
        });

        let targetedResults = {};
        // add "targeted" property to targeted hccs
        if (response[1].data) {
            Object.keys(response[1].data).forEach((key, index) => {
                targetedResults[key] = (response[1].data[key]).map(x => {
                    return { ...x, targeted: true, hccKey: 't' + x.hccValue };
                });
            });
        }

        //hccResults was previously called "hccResultsByVersion"; targetedResults was previously called "diagTargetByHCCVersion"
        let results = {};
        if (Object.keys(hccResults).length && Object.keys(targetedResults).length) {
            Object.keys(hccResults).forEach((key1, index1) => {
                Object.keys(targetedResults).forEach((key2, index2) => {
                    if (key1 === key2) {
                        results[key1] = ([...hccResults[key1]]).concat([...targetedResults[key2]]);
                    }
                    else if (!results[key1]) {
                        results[key1] = [...hccResults[key1]]
                    }
                    if (!results[key2]) {
                        results[key2] = [...targetedResults[key2]]
                    }
                })
            })
        }
        else if (!Object.keys(hccResults).length) {
            results = { ...targetedResults };
        }
        else {
            results = { ...hccResults };
        }
        results = this.showMAVersions(results);

        this.setState({
            resultsByVersion: results,
            hccProfileError: false
        });
        return results;
    }

    GroupingNlpInsightByDx = (nlpInsightDataResult) => {
        let nlpInsightDataFinal = [];
        Object.values(nlpInsightDataResult).forEach((nlp, index1) => {
            if (nlpInsightDataFinal.filter((x) => (x.dosEnd == nlp.dosEnd &&
                x.dosStart == nlp.dosStart &&
                x.diagnosisCode == nlp.diagnosisCode &&
                x.pageEnd == nlp.pageEnd &&
                x.pageStart == nlp.pageStart &&
                x.hccValue.includes(nlp.hccValue))).length == 0) {
                let arr = nlpInsightDataResult.filter(val => (val.dosEnd == nlp.dosEnd && val.dosStart == nlp.dosStart && val.diagnosisCode == nlp.diagnosisCode && val.pageEnd == nlp.pageEnd && val.pageStart == nlp.pageStart))
                let itemResult = [];
                Object.values(arr).forEach((item, index1) => {
                    if (itemResult.length == 0) {
                        itemResult = item;
                    }
                    else {
                        itemResult.hccValue = itemResult.hccValue + ', ' + item.hccValue;
                        itemResult.hccVersion = itemResult.hccVersion + ', ' + item.hccVersion;
                    }

                })

                nlpInsightDataFinal.push(itemResult);
            }
        })
        return nlpInsightDataFinal;
    }

    showMAVersions = (results) => {
        if (this.lineOfBusiness === "MA") {
            let filteredResults = [];
            if (this.state.defaultHCCVersion.hccVersions && this.state.defaultHCCVersion.hccVersions.length > 0) {
                this.state.defaultHCCVersion.hccVersions.forEach((key1, index1) => {
                    Object.keys(results).forEach((key2, index2) => {
                        if (key1 === key2) {
                            filteredResults[key2] = results[key2];
                        }
                    })
                });
            }
            return this.hccVersionSort(filteredResults);
        }
        else {
            return results;
        }
    }

    hccVersionSort = (result) => {
        const sortedKeys = Object.keys(result).sort().reverse();
        const sortedObject = {};
        sortedKeys.forEach((key) => {
            sortedObject[key] = result[key];
        });
        return sortedObject;
    }    

    navigateToPage = (pageNum) => {
        if(window.localStorage.getItem('popoutPageNumber')){
            window.localStorage.removeItem('popoutPageNumber');      
            window.localStorage.setItem('popoutPageNumber', pageNum);
        } else {
            window.localStorage.setItem('popoutPageNumber', pageNum);
        }
    }

    getReferenceData = () => {
        return axios.get(`/projects/${this.state.reviewProcessingUIConfig.projectID}/referenceData`,
        {
            params: {
                referenceDataType: 'providerType,source,medicalRecordType,rafType'
            }
        })
        .then((response) => {
            this.setState({ 
                medicalRecordTypeData: response.data.referenceDataList.filter(x => x.type === "medicalRecordType")[0],
                providerTypeData: response.data.referenceDataList.filter(x => x.type === "providerType")[0],
                sourceData: response.data.referenceDataList.filter(x => x.type === "source")[0],
                rafTypeData: response.data.referenceDataList.filter(x => x.type === "rafType")[0] 
            });
            this.getNlpInsights();
        })
        .catch((error) => {
            //this.props.toast('error', 'Could not load reference data.', 'Error');
        });
    }

    populateImageIDOptions = () => {
        return axios.get(`/medicalrecordreview/${this.state.reviewProcessingUIConfig.medicalRecordReviewID}/images`)
        .then((response) => {
            const mapToOptionRow = function(record) {
                return {
                    id: record.medicalRecordImageID,
                    title: record.medicalRecordImageID
                }
            }
            // remove duplicates with same medicalRecordImageID and map to options
            const imageRecords = (response.data.reduce((x, y) => x.findIndex(e => 
                e.medicalRecordImageID === y.medicalRecordImageID) < 0 ? [...x, y]: x, [])).map(mapToOptionRow);

            const userUploadedImages = (response.data.filter(x => {
                return x.retrievalSourceID === 5 && x.retrievalMethodID === 6;
            })).map(mapToOptionRow);

            if (this.state.reviewProcessingUIConfig.medicalRecordTypeID === MedicalRecordType.Scan) {
                imageRecords.push({ id: -1, title: 'Not available in MRR' });
            }

            this.setState({ 
                imageRecords: imageRecords,
                userUploadedImages: userUploadedImages
            });
        })
    }


    render() {
        return (
            this.state.reviewProcessingUIConfig &&
            <Loader loaded={this.state.pageLoaded}>
                <NLPTableImage key={this.state.medicalRecordImageId}
                    title={"NLP Insights Data"} nlpInsightData={this.state.nlpInsightData}
                    navigateToPage={this.navigateToPage}
                    tableCollapsed={this.props.tableCollapsed} collapseTable={this.props.collapseTable} displayCollapseButton={true}
                    toast={this.props.toast} medicalRecordTypeData={this.props.medicalRecordTypeData} sourceData={this.props.sourceData} 
                    providerTypeData={this.props.providerTypeData} rafTypeData={this.props.rafTypeData}
                    memberID={this.state.memberID}
                    medicalRecordReviewID={this.state.reviewProcessingUIConfig.medicalRecordReviewID}
                    reviewProcessingUIConfig={this.state.reviewProcessingUIConfig}
                    medicalRecordTypeID={this.state.medicalRecordTypeID}
                    reloadImages={this.populateImageIDOptions} imageRecords={this.props.imageRecords}
                    userID={this.state.reviewCurrentUserID} getDiagnosisData={this.getDiagnosisData} 
                    getDiagnosisHistoryData={this.getDiagnosisHistoryData} setDiagFormData={this.props.setDiagFormData}
                    reviewType={this.props.reviewType} isInsightsPoppedOut={true}
                    handlePaging={this.navigateToPage}
                />
            </Loader>
        );
    }
}

export default NLPTableImageNewWindow;