import React from 'react';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import AlertDialog from '../../Shared/AlertDialog';
import { Checkbox, Glyphicon } from 'react-bootstrap';
import CommunicationTypeChangeConfirmation from './CommunicationTypeChangeConfirmation';
import PracticeTypeSearch from './PracticeTypeSearch';
import EPECProviderExclusionButton from './EPECProviderExclusionButton';

class PracticeType extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            practiceData: [],
            practiceDataCount: 0,
            page: 1,
            pageSize: 10,
            sortBy: '',
            sortByDesc: '',
            showConfirmModal: false,
            communicationTypeOptions: this.props.outboundTypes.map(item => { return { value: item.practiceOutboundTypeID, text: item.outboundMethod } }),
            assignedPECOptions: [
                { value: 0, text: "Unassigned" },
                ...this.props.users.map((item) => { return { value: item.userID, text: item.fullName }; })
            ],
            showEPECNotificationModal: false
            ,showEPECModal: false
        }

        this.originalOutboundTypeID = null;
        this.originalAssignedPECUserID = null;
    }

    getPracticeData = (searchParams, page, pageSize, sortBy, sortByDesc) => {
        axios.get('/practice/practiceEngagement', {
            params: {
                providerOrg: (searchParams.providerID == null) ? '' : searchParams.providerID,
                practiceID: (searchParams.practiceID == null) ? '' : searchParams.practiceID,
                practiceName: (searchParams.practiceGroupingID == null) ? '' : searchParams.practiceGroupingID,
                pageNumber: page,
                pageSize: pageSize,
                sortBy: sortBy,
                sortByDesc: sortByDesc
            }
        })
        .then(response => {
            this.setState({ practiceData: response.data.items, practiceDataCount: response.data.totalItems, page: page });
        })
        .catch((error) => {
            this.props.toast('error', error.response.data ? error.response.data.message : 'Could not load practice data.', 'Error');
        });
    }

    setProviderOrg = (providerOrg) => {
        this.setState({ providerOrg: providerOrg });
    }

    setPractice = (practiceName) => {
        this.setState({ practiceName: practiceName });
    }

    setPracticeID = (practiceID) => {
        this.setState({ practiceID: practiceID });
    }

    clearSearch = () => {
        this.setState({
            providerOrg: '',
            practiceName: '',
            practiceID: '',
            practiceData: [],
            page: 1
        });
    }

    //format dropdown cell selections (cell contents)
    formatSelect = (cell, options) => {
        if(cell && options) {
            if(cell === "0") {
                return '';
            }
            else {
                const targetOption = options.find(option => option.value === cell);
                return targetOption ? targetOption.text : '';
            }
        }
    }

    formatCommunicationType = (cell) => {
        return this.formatSelect(cell, this.state.communicationTypeOptions);
    }

    formatAssignedPEC = (cell) => {
        if (cell === 0) {
            return 'Unassigned';
        }
        else {
            if (this.state.assignedPECOptions) {
                const targetOption = this.state.assignedPECOptions.find(option => option.value === cell);
                return targetOption ? targetOption.text : '';
            }
            else  {
                return '';
            }
        }
    }

    formatEngaged = (isEngaged, row) => {
        return <Checkbox style={{'verticalAlign': 'middle'}} defaultChecked={isEngaged} onChange={this.handleEngagedChange.bind(this, row)} />
    }

    formatEPECExcluded = (cell, row) => {
        if (row && row.outboundTypeID === 6) {
            return <EPECProviderExclusionButton practiceID={row.practiceID}
                       practiceName={row.practiceName} />           
        }
    }

    updateCommunicationType = () => {
        this.saveRow(this.state.selectedRow);
        this.handleModalToggle();
    }

    handleEngagedChange = (row) => {
        const currentPracticeData = [...this.state.practiceData];
        const index = currentPracticeData.indexOf(row);
        currentPracticeData[index].isEngaged = !currentPracticeData[index].isEngaged;
        this.setState({ practiceData: currentPracticeData });
        this.saveRow(row);
        this.props.refreshPractices();
    }

    saveRow = (row) => {
        axios.put('/practice/practiceEngagement', {
            PracticeID: row.practiceID,
            PracticeName: row.practiceName,
            ProviderOrganization: row.providerOrganization,
            IsEngaged: row.isEngaged,
            OutboundTypeID: row.outboundTypeID
        })
        .catch(error => {
            this.props.toast('error', error.response.data ? error.response.data.message : 'Could not save practice data.', 'Error');
        });
    }

    updateAssignedPEC = (row) => {
        axios.put(`/practice/${row.practiceID}/UserAssignments`, {
            UserID: row.assignedPECUserID
        })
        .then(res => {
            const selectedOption = this.state.assignedPECOptions.find(option => option.value === row.assignedPECUserID);
            this.props.updateAssignedPECs(selectedOption.value, selectedOption.text);
        })
        .catch(error => {
            this.props.toast('error', error.response.data ? error.response.data.message : 'Could not save assigned PEC.', 'Error');

            //reset assigned PEC dropdown to previous value
            const currentPracticeData = [...this.state.practiceData];
            const index = currentPracticeData.indexOf(row);
            currentPracticeData[index].assignedPECUserID = this.originalAssignedPECUserID;
            this.setState({ practiceData: currentPracticeData });
        });
    }

    handleModalToggle = () => {
        this.setState({ showConfirmModal: !this.state.showConfirmModal });
    }

    handleEPECNotificationModalToggle = () => {
        this.setState({ showEPECNotificationModal: !this.state.showEPECNotificationModal });
    }

    checkIfRosterSent = (row) => {
        axios.get('/practice/rosterStatus', {
            params: {
                PracticeID: row.practiceID
            }
        })
        .then(response => {
            this.setState({
                selectedRow: row
            }, () => {
                if (response.data) {
                    this.setState({ showConfirmModal: true });
                }
                else {
                    this.setState({ showEPECNotificationModal: true });
                }
            });
        })
        .catch((error) => {
            this.props.toast('error', error.response.data.message ? error.response.data.message : 'Could not load the roster status data.', 'Error');
        });
    }

    render() {
        const options = {
            page: this.state.page,
            sizePerPageList: [10, 25, 50, 100, 250]
                .map(item => { return { value: item, text: item.toString() } }),
            sizePerPage: this.state.pageSize,                        
            pageStartIndex: 1,
            onPageChange: (page, sizePerPage) => {
                this.setState({
                    page: page,
                    pageSize: sizePerPage
                })
                const searchParams = { providerID: this.state.providerOrg, practiceGroupingID: this.state.practiceName, practiceID: this.state.practiceID }
                this.getPracticeData(searchParams, page, sizePerPage, this.state.sortBy, this.state.sortByDesc)
            },
            onSortChange: (sortName, sortOrder) => {
                this.setState({
                    sortBy: sortName,
                    sortByDesc: sortOrder === "desc"
                });
                const searchParams = { providerID: this.state.providerOrg, practiceGroupingID: this.state.practiceName, practiceID: this.state.practiceID }
                this.getPracticeData(searchParams, this.state.page, this.state.pageSize, sortName, sortOrder === "desc");
            }
        }

        const onBeforeSaveCell = (row, cellName, cellValue) => {
            if (cellName === 'outboundTypeID') {
                this.originalOutboundTypeID = row.outboundTypeID;
            }
            else if (cellName === 'assignedPECUserID') {
                this.originalAssignedPECUserID = row.assignedPECUserID;
            }
        }

        const onAfterSaveCell = (row, cellName, cellValue) => {
            if (cellName === 'outboundTypeID' && cellValue !== this.originalOutboundTypeID.toString()) {
                row.outboundTypeID = parseInt(cellValue, 10);
                const epecOption = row.outboundTypeID === 6;// 6 means EPEC
                if(epecOption){
                    this.checkIfRosterSent(row);                  
                }                                  
                else{                   
                    this.setState({
                        selectedRow: row,
                    }, () => { this.setState({ showConfirmModal: true }); });
                }
            }
            else if (cellName === 'assignedPECUserID' && cellValue !== this.originalAssignedPECUserID.toString()) {
                row.assignedPECUserID = parseInt(cellValue, 10);
                this.updateAssignedPEC(row);
            }
        }
        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            beforeSaveCell: onBeforeSaveCell,
            afterSaveCell: onAfterSaveCell
        };

        return (
            <div style={{marginTop: '2rem'}}>
                <PracticeTypeSearch
                    router={this.props.router}
                    toast={this.props.toast}
                    providers={this.props.providers}
                    practices={this.props.practices}
                    getPracticeData={this.getPracticeData}
                    setProviderOrg={this.setProviderOrg}
                    setPractice={this.setPractice}
                    setPracticeID={this.setPracticeID}
                    clearSearch={this.clearSearch}
                    page={this.state.page}
                    pageSize={this.state.pageSize} />
                <hr/>
                <div>
                    <BootstrapTable data={this.state.practiceData} options={options} fetchInfo={{ dataTotalSize: this.state.practiceDataCount}}
                        cellEdit={cellEditProp} maxHeight="400px" hover pagination remote>
                        <TableHeaderColumn dataField="providerOrganization" editable={{ readOnly: true }} dataSort>Provider Org</TableHeaderColumn>
                        <TableHeaderColumn dataField="practiceName" editable={{ readOnly: true }} dataSort>Practice Name</TableHeaderColumn>
                        <TableHeaderColumn dataField="practiceID" editable={{ readOnly: true }} isKey dataSort>Practice ID</TableHeaderColumn>
                        <TableHeaderColumn dataField="assignedPECUserID" editable={{ type: 'select', options: { values: this.state.assignedPECOptions } }}
                            dataAlign="left" dataFormat={ this.formatAssignedPEC } dataSort>
                            Assigned PEC<Glyphicon glyph="pencil" style={{marginLeft: '0.8rem'}}/>
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="outboundTypeID" editable={{ type: 'select', options: { values: this.state.communicationTypeOptions } }}
                            dataAlign="left" dataFormat={ this.formatCommunicationType } dataSort>
                            Communication Type<Glyphicon glyph="pencil" style={{marginLeft: '0.8rem'}}/>
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="isEngaged" editable={{ readOnly: true }} dataFormat={ this.formatEngaged } dataSort>
                            Engaged?<Glyphicon glyph="pencil" style={{marginLeft: '0.8rem'}}/>
                        </TableHeaderColumn>
                        <TableHeaderColumn dataFormat={this.formatEPECExcluded} editable={false}>
                            EPEC Excluded
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div>
                <CommunicationTypeChangeConfirmation visible={this.state.showConfirmModal} handleModalToggle={this.handleModalToggle} title="Confirm Save?"
                    confirmLabel="Confirm" handleConfirm={this.updateCommunicationType} practiceData={this.state.practiceData} originalOutboundTypeID={this.originalOutboundTypeID}
                    row={this.state.selectedRow} communicationTypeOptions={this.state.communicationTypeOptions} cancelLabel={"Cancel"} confirmStyle="success" glyphicon="ok" />
                <AlertDialog visible={this.state.showEPECNotificationModal} handleModalToggle={this.handleEPECNotificationModalToggle} title="Warning"
                    message="Electronic PEC is not set up for this practice. Please contact PEC_support team to establish it before switching Communication Type."
                    cancelLabel="Ok" showConfirm={false} renderInnerDiv={true} />
            </div>
        );
    }
}

export default PracticeType;