import React, { Component } from 'react';
import axios from 'axios';
import Loader from 'react-loader';
import NLPInsightHCCProfileContainer from '../NLPInsightsByHCC/NLPInsightHCCProfileContainer';


import { Col, Grid, Row, Glyphicon, DropdownButton, Tabs, Tab, MenuItem } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Error from '../../../Shared/Error';
import Info from '../../../Shared/Info';

import { PDFLinkService, PDFFindController, PDFViewer, EventBus } from 'pdfjs-dist/web/pdf_viewer';
import * as pdfjs from 'pdfjs-dist/build/pdf';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import 'pdfjs-dist/web/pdf_viewer.css';
import ReactPopout from '../../../../external/ReactPopout';
import { PdfViewerHOC } from './PdfViewerHOC';


class NLPInsightHccNewWindow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nlpInsightData: [],
            shownlpInsightDataError: false,
            hccVersionData: [],
            hccVersionDataError: false,
            selectedHCCVersion: null,
            medicalRecordImageId: 0,
            medicalRecordReviewID: 0,
            imageData: null,
            imageType: null,
            imageLoading: false,
            showImageError: false,
            pageLoaded: false,
            tableCollapsed: true,
            insightsHccIsPoppedOut: false,
            data: null,
            pdfViewer: null,
            isPoppedOut: props.pdfIsPoppedOut,
            handlePaging: props.handlePaging,
            pdfFile: null,
            jumpToPage: 0
        };

        this.pdfUrlsExisted = [];

    }

    componentDidMount() {
        window.addEventListener('storage', (event) => {
            if (event.storageArea != localStorage) return;
            if (event.key === 'popoutPageNumber') {
                // Do something with event.newValue
                this.navigateToPage(Number(event.newValue));
            }
        });
        this.getHCCVersions();
        this.getNlpInsights(false);

    }

    componentDidUpdate(prevProps) {
        if (prevProps.pdfIsPoppedOut !== this.props.pdfIsPoppedOut) {
            this.setState({ isPoppedOut: this.props.pdfIsPoppedOut });
            if (!this.props.pdfIsPoppedOut) {
                this.getNlpInsights(false);
            }
        }
    }


    getNlpInsights = (reload) => {
        Promise.all([
        axios.get(`/mrrnlpapi/v1/FilterdMedicalRecordImageInsightByHcc/${this.props.location.query.medicalRecordReviewID}`) //new nlp insights data
        ])
        .then((response) => { 
            let nlpInsightDataFilter = [];
            let hccResults=[];
            response[0].data && Object.keys(response[0].data).forEach((key, index) => {
                let version=  response[0].data[key].hccVersion!=null ? response[0].data[key].hccVersion.split(',') :null;
                {version!=null && version.sort((a,b) => a.item > b.item ?1:-1).map((item) => 
                {
                    if(item!=null && !hccResults.some(x=> x.item.includes(item)))
                    {
                        hccResults.push({item});
                    } 
                })}});
                    hccResults = this.showMAVersions(hccResults, reload);
                
                if(response[0].status === 200 && response[0].data.length > 0){ 
                    
                    Object.keys(response[0].data).forEach((key, index) => {                        
                        if(this.state.selectedHCCVersion && response[0].data[key].hccVersion!=null 
                            && response[0].data[key].hccVersion.toUpperCase().includes(this.state.selectedHCCVersion.toUpperCase().trim())) 
                            {
                                    nlpInsightDataFilter[index]=(response[0].data[key]);
                            }
                        
                    });                    
                    
                    this.setState({                        
                        nlpInsightData: nlpInsightDataFilter,
                        medicalRecordImageId: response[0].data ==null ? null : response[0].data[0].medicalRecordImageID,
                        shownlpInsightDataError: false,
                        pageLoaded: true
                    });
                }
                else{
                    this.setState({
                        shownlpInsightDataError: true,
                        pageLoaded: true
                    });
                }
                              
                this.setState({
                    resultsByVersion: hccResults,
                    pageLoaded: true,
                    hccProfileError: false
                     
                }); 
        })
        .catch((error) => {
            console.log("NLPInsights-getNlpInsights " + error);
            this.setState({
                shownlpInsightDataError: true,
                pageLoaded: true
            });
        });        
    }

    getHCCVersions = () => {
        axios.get(`/mrrnlpapi/v1/MedicalRecordImages/HCCVersion`)
            .then((response) => {
                if (response.status === 200 && response.data.length > 0) {
                    this.setState({
                        hccVersionData: response.data.sort((a,b) => a.item > b.item ?1:-1),
                        hccVersionDataError: false,
                        pageLoaded: true
                    });

                    const lineOfBusiness = localStorage.getItem('lineOfBusiness');
                    if (lineOfBusiness == "MA" && this.state.hccVersionData.length > 0) {
                        this.setState({
                            selectedHCCVersion: this.state.hccVersionData.some(item => item.toUpperCase() == 'V28') ? 'V28' :
                                (this.state.hccVersionData.some(item => item.toUpperCase() == 'V24') ? 'V24' : this.state.hccVersionData[0])
                        });
                    }
                    else {
                        this.setState({ selectedHCCVersion: this.state.hccVersionData.some(item => item.toUpperCase() == 'V08') ? 'V08' : this.state.hccVersionData[0] });
                    }
                }
                else {
                    this.setState({
                        hccVersionDataError: true,
                        pageLoaded: true
                    });
                }



            })
            .catch((error) => {
                console.log("NLPInsightsByHCC-getHCCVersions " + error);
                this.setState({
                    hccVersionDataError: true,
                    pageLoaded: true
                });
            });
    }

    getDefaultTab = (results) => {
        if (this.state.defaultHCCVersion) {
            let hccVersion = this.state.defaultHCCVersion;
            for (let i = 0; i < hccVersion.length; i++) {
                if (Object.keys(results).some(key1 => key1 === hccVersion[i])) {
                    return Object.keys(results).indexOf(hccVersion[i]);
                }
            }
        }
        return 0;
    }

    selectTab = (eventKey) => {
        this.setState({ selectedTab: eventKey });
    }

    showMAVersions = (results, reload) => {
        const lineOfBusiness = localStorage.getItem('lineOfBusiness');
        let filteredResults = [];
        if(lineOfBusiness === "MA"){            
            if(this.state.hccVersionData && this.state.hccVersionData.length > 0 && results.length > 0){
                this.state.hccVersionData.forEach((key1, index1) => {
                    Object.values(results).forEach((key2, index2) => {
                        if(key1.toUpperCase().trim() === key2.item.toUpperCase().trim()){
                            filteredResults.push(key2.item);
                        }
                    })
                });
            }
            if(!reload)
            this.setState({selectedHCCVersion :filteredResults[0]});

            return filteredResults;
        }
        else {
            if(this.state.hccVersionData && this.state.hccVersionData.length > 0)
            {
                this.state.hccVersionData.forEach((key1, index1) => {                    
                            filteredResults.push(key1);
                        });
            }
            return filteredResults;
        }
    }
    handleSelectHCCVersion = (hccVersion) => {
        this.setState({ selectedHCCVersion: hccVersion });
        this.getNlpInsights(true);
        this.renderResults();
    }

    navigateToPage = (pageNum) => {
        if (window.localStorage.getItem('popoutPageNumber')) {
            window.localStorage.removeItem('popoutPageNumber');
            window.localStorage.setItem('popoutPageNumber', pageNum);
        } else {
            window.localStorage.setItem('popoutPageNumber', pageNum);
        }
    }

    collapseTable = () => {
        this.setState({ tableCollapsed: !this.state.tableCollapsed });
    }


    renderResults = () => {

        return (
            <div style={{ maxHeight: `600px`, overflowY: 'scroll', paddingLeft: '15px', paddingRight: '30px' }}>
                <section>
                    {
                        this.state.shownlpInsightDataError && <Info infoType='noData' style={{ marginTop: '5rem' }} />
                    }
                    {
                        !this.state.shownlpInsightDataError && !this.props.insightsHccIsPoppedOut &&
                        <NLPInsightHCCProfileContainer key={this.state.medicalRecordImageId}
                            title={"NLP Insights Data"} nlpInsightData={this.state.nlpInsightData}
                            navigateToPage={this.navigateToPage}
                            tableCollapsed={this.state.tableCollapsed} collapseTable={this.collapseTable} displayCollapseButton={true}
                            medicalRecordReviewID={this.state.medicalRecordReviewID} toast={this.props.toast}
                            reloadImages={this.props.reloadImages} imageRecords={this.props.imageRecords}
                            userID={this.props.userID} getDiagnosisData={this.getDiagnosisData}
                            getDiagnosisHistoryData={this.getDiagnosisHistoryData} setDiagFormData={this.props.setDiagFormData}
                            reviewType={'InitialCoding'} isInsightsPoppedOut={true}
                            toggleInsightsHccWindow={this.toggleInsightsHccWindow} handlePaging={this.handlePaging}
                        />
                    }
                </section>
            </div>
        )
    }

    render() {
        const dropdownTitle = (this.state.resultsByVersion !=null && [this.state.selectedHCCVersion][0] !=null)  ? [this.state.selectedHCCVersion][0].toUpperCase() : "";
        console.log("pageLoaded: " + this.state.pageLoaded);
        console.log("pdfjs-version"); console.log(pdfjs.version);

        const options = {
            toolbar: 'no',
            location: 'no',
            directories: 'no',
            status: 'no',
            menubar: 'no',
            scrollbars: 'yes',
            resizable: 'yes',
            width: window.screen.width * .8,
            height: window.screen.height * .8,
            top: (o, w) => (w.innerHeight - o.height) / 2 + w.screenY,
            left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX
        };

        return (
            <Grid fluid>
                <Loader loaded={this.state.pageLoaded}>
                    <Row style={{ height: '600px' }}>
                        <Col xs={12} style={{ paddingRight: '0px', paddingLeft: '0px' }}>

                            <Row>
                                <Col xs={12} style={{ paddingRight: '5rem', paddingLeft: '0px' }}>
                                    <div style={{ paddingTop: '1.2rem', paddingBottom: '1.2rem', display: "inline-block", float: "right" }}>
                                        {
                                            this.state.resultsByVersion && Object.keys(this.state.resultsByVersion).length > 0 &&
                                            <DropdownButton bsStyle="default" bsSize="xsmall" title={dropdownTitle} id="hccVersionDropdown" style={{ marginLeft: '1rem', fontWeight: 'bold' }}>
                                                <MenuItem header>HCC Version</MenuItem>
                                                {
                                                    Object.keys(this.state.resultsByVersion).map((key, index) => {
                                                        return this.state.resultsByVersion[key] ? (
                                                            <MenuItem key={index} eventKey={index} onSelect={() => this.handleSelectHCCVersion(this.state.resultsByVersion[key])}>{this.state.resultsByVersion[index].toUpperCase()}</MenuItem>
                                                        ) : null
                                                    })
                                                }
                                            </DropdownButton>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{}}>
                                <Col xs={12} style={{ paddingRight: '0px', paddingLeft: '0px' }}>

                                    <Tabs defaultActiveKey={0} activeKey={this.state.selectedTab} onSelect={this.selectTab} id="hccResultsTabs">
                                        {
                                            !this.state.hccVersionDataError && this.state.resultsByVersion &&
                                            this.renderResults()
                                        }
                                        {
                                            //this.state.hccVersionDataError && this.renderEmptyTable()
                                        }
                                    </Tabs>


                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Loader>
            </Grid>
        );
    }
}

export default NLPInsightHccNewWindow;