import React from 'react';
import axios from 'axios';
import { Tabs, Tab } from 'react-bootstrap';
import { MedicalRecordType } from '../../../../enums/MedicalRecordType';
import { Program } from '../../../../enums/Program';
import DiagnosisCaptureRedux from './Diagnosis/DiagnosisCaptureRedux.jsx'
import DiagnosisCaptureHistory from './Diagnosis/DiagnosisCaptureHistory';
import OpportunityCapture from './Opportunity/OpportunityCapture';
import OpportunityCaptureHistory from './Opportunity/OpportunityCaptureHistory';
import StarsCapture from './Stars/StarsCapture';
import StarsCaptureHistory from './Stars/StarsCaptureHistory';

class CaptureContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // reference data variables
            rejectionReasons: null,
            starsRejectionReasons: null,
            diagnosisComments: null,
            opportunityReviewComments: null,
            commentGroupOptions: null,
            medicalRecordTypeData: null,
            sourceData: null,
            providerTypeData: null,
            rafTypeData: null,
            imageRecords: null,
            userUploadedImages: null,
            // radv reference
            radvScores: null,
            hccHierarchies: null,
            radvAuditEnrolleeHCCs: null,
            // capture grid variables
            diagnosisData: null,
            diagnosisHistoryData: null,
            mrrWithProviderMaster: null,
            opportunityData: null,
            opportunityHistoryData: null,
            starsData: null,
            starsHistoryData: null,
            memberDemo: null
            //loaded: false
        }
    }

    componentWillMount() {
        
        // if (this.props.reviewProcessingUIConfig.medicalRecordReviewID !== nextProps.reviewProcessingUIConfig.medicalRecordReviewID) {
            const promises = [this.getReferenceData(), this.populateDiagComments(), this.populateRejectionReasons(),
                this.populateImageIDOptions(), this.getCommentGroupOptions(), this.getMRRProviderNPIs(), this.getMemberDemographic()];

            if (this.props.reviewProcessingUIConfig.includeDiagnosisTabs) {
                promises.push(this.getDiagnosisData(), this.getDiagnosisHistoryData(), this.getMRRWithProviderMaster(),
                    this.getRADVScores(), this.getHCCHierarchies(), this.getRADVAuditHCCs());
            }
            if (this.props.reviewProcessingUIConfig.includeOpportunityTabs) {
                promises.push(this.getOpportunityData(), this.getOpportunityHistoryData(), this.populateOppComments());
            }
            if (this.props.reviewProcessingUIConfig.includeStarsTab) {
                promises.push(this.getStarsData(), this.getStarsHistoryData(), this.getQualityMeasures(), this.populateStarsRejectionReasons());
            }

            Promise.all(promises).then((response) => {
                //this.setState({ loaded: true });
            })
            .catch((error) => {
                //this.setState({ loaded: true });
                //this.props.toast('error', 'Could not load capture data.', 'Error');
            })
        // }

        this.defaultTab = getDefaultTab(this.props.reviewProcessingUIConfig);

        function getDefaultTab(reviewProcessingUIConfig) {
            const visibleTabs = [];
            if (reviewProcessingUIConfig.includeDiagnosisTabs) {
                visibleTabs.push(1);
            }
            if (reviewProcessingUIConfig.includeOpportunityTabs) {
                visibleTabs.push(3);
            }
            if (reviewProcessingUIConfig.includeStarsTab) {
                visibleTabs.push(5);
            }

            if (reviewProcessingUIConfig.projectDefaultProgramID === Program.Stars && visibleTabs.includes(5)) {
                return 5;
            }
            else if (reviewProcessingUIConfig.projectDefaultProgramID === Program.Opportunities && visibleTabs.includes(3)) {
                return 3;
            }
            else if (visibleTabs.includes(1)) {
                return 1;
            }
            else {
                return visibleTabs.length > 0 ? visibleTabs[0] : 1;
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        //refresh diags on claims row diag add
        const triggerRefresh = nextProps.diags.find(x => x.type === "SET_TRIGGER_DIAG_REFRESH");
        if (triggerRefresh && triggerRefresh.triggerDiagRefresh) {
            this.getDiagnosisData();
            this.props.setTriggerDiagRefresh(false);
        }
    }
    
    // --- Get Functions ---//
    // retrieve reference data for provider type, source, medicalRecord type, and raf type
    getReferenceData = () => {
        return axios.get(`/projects/${this.props.reviewProcessingUIConfig.projectID}/referenceData`,
        {
            params: {
                referenceDataType: 'providerType,source,medicalRecordType,rafType'
            }
        })
        .then((response) => {
            this.setState({ 
                medicalRecordTypeData: response.data.referenceDataList.filter(x => x.type === "medicalRecordType")[0],
                providerTypeData: response.data.referenceDataList.filter(x => x.type === "providerType")[0],
                sourceData: response.data.referenceDataList.filter(x => x.type === "source")[0],
                rafTypeData: response.data.referenceDataList.filter(x => x.type === "rafType")[0] 
            });

        })
        .catch((error) => {
            //this.props.toast('error', 'Could not load reference data.', 'Error');
        });
    }

    // retrieve rejection reasons
    populateRejectionReasons = () => {
        return axios.get(`/projectCommentDefinition`, {
            params: {
                projectId: this.props.reviewProcessingUIConfig.projectID,
                commentType: "RejectionReason"
            }
        })
        .then((response) => {
            this.setState({ rejectionReasons: response.data });
        })
    }

    // retrieve quality capture rejection reasons
    populateStarsRejectionReasons = () => {
        return axios.get(`/projectCommentDefinition`, {
            params: {
                projectId: this.props.reviewProcessingUIConfig.projectID,
                commentType: "QualityCaptureRejectionReason"
            }
        })
        .then((response) => {
            this.setState({ starsRejectionReasons: response.data });
        })
    }

    // retrieve opportunity review level comments
    populateOppComments = () => {
        return axios.get(`/projectCommentDefinition`, {
            params: {
                projectId: this.props.reviewProcessingUIConfig.projectID,
                commentType: "ReviewOpportunityLevel"
            }
        })
        .then((response) => {
            this.setState({ opportunityReviewComments : response.data });
        })
    }

    getCommentGroupOptions = () => {
        return axios.get(`/commentGroup`)
        .then((response) => {
            this.setState({ commentGroupOptions : response.data });
        })
    }

    // retrieve image records for review
    populateImageIDOptions = () => {
        return axios.get(`/medicalrecordreview/${this.props.reviewProcessingUIConfig.medicalRecordReviewID}/images`)
        .then((response) => {
            const mapToOptionRow = function(record) {
                return {
                    id: record.medicalRecordImageID,
                    title: record.medicalRecordImageID
                }
            }
            // remove duplicates with same medicalRecordImageID and map to options
            const imageRecords = (response.data.reduce((x, y) => x.findIndex(e => 
                e.medicalRecordImageID === y.medicalRecordImageID) < 0 ? [...x, y]: x, [])).map(mapToOptionRow);

            const userUploadedImages = (response.data.filter(x => {
                return x.retrievalSourceID === 5 && x.retrievalMethodID === 6;
            })).map(mapToOptionRow);

            if (this.props.reviewProcessingUIConfig.medicalRecordTypeID === MedicalRecordType.Scan) {
                imageRecords.push({ id: -1, title: 'Not available in MRR' });
            }

            this.setState({ 
                imageRecords: imageRecords,
                userUploadedImages: userUploadedImages
            });
        })
    }

    getMRRProviderNPIs = () => {
        axios.get(`/mrrprovidernpis/${this.props.reviewProcessingUIConfig.medicalRecordReviewID}`)
        .then((response) => {
            this.setState({ mrrProviderNPIs: response.data });
        })
    }

    //----- RADV retrievals -----//
    getRADVScores = () => {
        return axios.get(`/radvscores`)
        .then((response) => {
            this.setState({ radvScores: response.data });
        })
    }

    getHCCHierarchies = () => {
        return axios.get(`/hcchierarchies`)
        .then((response) => {
            this.setState({ hccHierarchies: response.data });
        })
    }

    getRADVAuditHCCs = () => {
        const radvAuditEnrolleeID = this.props.reviewProcessingUIConfig.radvEnrollee ? 
            this.props.reviewProcessingUIConfig.radvEnrollee.radvAuditEnrolleeID : null;
        if (radvAuditEnrolleeID) {
            axios.get(`/RADVAuditEnrollees/${radvAuditEnrolleeID}/RADVAuditHCCs`)
            .then((response) => {
                this.setState({ radvAuditEnrolleeHCCs: response.data });
            })
        }
    }

    // retrieve diag level comments
    populateDiagComments = () => {
        return axios.get(`/projectCommentDefinition`, {
            params: {
                projectId: this.props.reviewProcessingUIConfig.projectID,
                commentType: "ReviewDiagLevel"
            }
        })
        .then((response) => {
            this.setState({ diagnosisComments: response.data });
        })
    }

    // retrieve diagnosis capture data for child component - DiagnosisCapture
    getDiagnosisData = () => {
        return axios.get(`/medicalRecordReview/${this.props.reviewProcessingUIConfig.medicalRecordReviewID}/medicalRecordDiag/`)
        .then((response) => {
            this.setState({
                diagnosisData: response.data,
            }, () => {
                this.props.setDiagIDList(response.data.map(x => x.medicalRecordDiagID));
                this.props.setClaimsDiagInitiatorIDList(response.data.map(x => {
                    return {
                        initiatorIdentifier: x.initiatorIdentifier,
                        diagnosisCD: x.diagnosisCD
                    }
                }));
            });
            if (response.data.length > 0) {
                this.props.setReviewHasAddedRecords();
            }
        })
        .catch((error) => {
            //this.props.toast('error', 'Could not load diagnosis data.', 'Error');
        });
    }

    // retrieve diagnosis history data for child component - DiagnosisCaptureHistory
    getDiagnosisHistoryData = () => {
        return axios.get(`/member/${this.props.reviewProcessingUIConfig.memberInternalKey}/diaghistory`)
        .then((response) => {
            this.setState({ diagnosisHistoryData: response.data });
        })
        .catch((error) => {
            //this.props.toast('error', 'Could not load diagnosis history data.', 'Error');
        })
    }
    
    // retrieve opportunity capture data for child component - OpportunityCapture
    getOpportunityData = () => {
        return axios.get(`/medicalRecordReview/${this.props.reviewProcessingUIConfig.medicalRecordReviewID}/medicalRecordOpportunity/`)
        .then((response) => {
            this.setState({ opportunityData: response.data });
            if (response.data.length > 0) {
                this.props.setReviewHasAddedRecords();
            }
        })
        .catch((error) => {
            //this.props.toast('error', 'Could not load opportunity data.', 'Error');
        });
    }

    // retrieve opportunity history data for child component - OpportunityCaptureHistory
    getOpportunityHistoryData = () => {
        return axios.get(`/member/${this.props.reviewProcessingUIConfig.memberInternalKey}/opphistory`)
        .then((response) => {
            this.setState({ opportunityHistoryData: response.data });
        })
        .catch((error) => {
            //this.props.toast('error', 'Could not load opportunity history data.', 'Error');
        })
    }

    // retrieve stars capture data for child component - StarsCapture
    getStarsData = () => {
        return axios.get(`/medicalRecordQualityCapture`, {
            params: {
                medicalRecordReviewID: this.props.reviewProcessingUIConfig.medicalRecordReviewID
            }
        })
        .then((response) => {
            this.setState({ starsData: response.data });
            if (response.data.length > 0) {
                this.props.setReviewHasAddedRecords();
            }
        })
        .catch((error) => {
            //this.props.toast('error', 'Could not load stars data.', 'Error');
        })
    }

    getStarsHistoryData = () => {
        return axios.get(`/member/${this.props.reviewProcessingUIConfig.memberInternalKey}/starsHistory`)
        .then((response) => {
            this.setState({ starsHistoryData: response.data });
        })
        .catch((error) => {
            //this.props.toast('error', 'Could not load stars history data.', 'Error');
        })
    }

    getQualityMeasures = () => {
        return axios.get(`/QualityMeasure`)
        .then((response) => {
            this.setState({ qualityMeasures: response.data });
        })
    }

    getMRRWithProviderMaster = () => {
        return axios.get(`/medicalrecordreview/${this.props.reviewProcessingUIConfig.medicalRecordReviewID}/providermaster`)
        .then((response) => {
            this.setState({ mrrWithProviderMaster: response.data });
        })
    }

    getMemberDemographic = () => {
        axios.get(`/memberService/v1/member/${this.props.reviewProcessingUIConfig.memberInternalKey}/memberDemographic/`)
        .then((response) => {
            this.setState({
                memberDemo: response.data,
            });
        })
    }

    render() {
        const radvEnrolleeID = this.props.reviewProcessingUIConfig.radvEnrollee ? this.props.reviewProcessingUIConfig.radvEnrollee.enrolleeID : null;
        const member = this.state.memberDemo ? this.state.memberDemo.member : null;
        return (
            //todo: add loader for this section?
            // <Loader loaded={this.state.loaded}>
                <Tabs defaultActiveKey={this.defaultTab} id="capture-tabs">
                    {
                        this.props.reviewProcessingUIConfig.includeDiagnosisTabs &&
                        <Tab eventKey={1} title="Diagnosis">
                            <DiagnosisCaptureRedux diagnosisData={this.state.diagnosisData} isRADV={this.props.reviewProcessingUIConfig.isRADV}
                                rejectionReasons={this.state.rejectionReasons} diagnosisComments={this.state.diagnosisComments}
                                medicalRecordTypeData={this.state.medicalRecordTypeData} sourceData={this.state.sourceData}
                                providerTypeData={this.state.providerTypeData} rafTypeData={this.state.rafTypeData}
                                imageRecords={this.state.imageRecords} userUploadedImages={this.state.userUploadedImages}
                                medicalRecordTypeID={this.props.reviewProcessingUIConfig.medicalRecordTypeID}
                                isQA={this.props.reviewProcessingUIConfig.isQA} isComplete={this.props.reviewProcessingUIConfig.isComplete}
                                isMD={this.props.reviewProcessingUIConfig.isMD} isCoderQA={this.props.reviewProcessingUIConfig.isCoderQA}
                                isRiskMitigation={this.props.reviewProcessingUIConfig.isRiskMitigation} coderQAQueueID={this.props.reviewProcessingUIConfig.coderQAQueueID}
                                userID={this.props.reviewProcessingUIConfig.userID} memberID={this.props.reviewProcessingUIConfig.memberInternalKey}
                                medicalRecordReviewID={this.props.reviewProcessingUIConfig.medicalRecordReviewID}
                                mrrWithProviderMaster={this.state.mrrWithProviderMaster} member={member}
                                maxDateDifference={this.props.reviewProcessingUIConfig.maxDayDifferenceBetweenDateOfServiceFromAndDateOfServiceTo}
                                radvScores={this.state.radvScores} hccHierarchies={this.state.hccHierarchies} radvAuditEnrolleeHCCs={this.state.radvAuditEnrolleeHCCs}
                                getDiagnosisData={this.getDiagnosisData} getDiagnosisHistoryData={this.getDiagnosisHistoryData} 
                                toast={this.props.toast} projectID={this.props.reviewProcessingUIConfig.projectID} radvEnrolleeID={radvEnrolleeID}
                                reloadImages={this.populateImageIDOptions} autoClaimsLinkingEnabled={this.props.reviewProcessingUIConfig.autoClaimsLinkingEnabled}
                                parallelClaimsDiagCaptureEnabled={this.props.reviewProcessingUIConfig.parallelClaimsDiagCaptureEnabled} />
                        </Tab>
                    }
                    {
                        this.props.reviewProcessingUIConfig.includeDiagnosisTabs &&
                        <Tab eventKey={2} title="Diagnosis History">
                            <DiagnosisCaptureHistory diagnosisData={this.state.diagnosisHistoryData} 
                                isRADV={this.props.reviewProcessingUIConfig.isRADV}
                                rejectionReasons={this.state.rejectionReasons} diagnosisComments={this.state.diagnosisComments}
                                medicalRecordTypeData={this.state.medicalRecordTypeData} sourceData={this.state.sourceData}
                                providerTypeData={this.state.providerTypeData} rafTypeData={this.state.rafTypeData}
                                isQA={this.props.reviewProcessingUIConfig.isQA} isComplete={this.props.reviewProcessingUIConfig.isComplete}
                                isMD={this.props.reviewProcessingUIConfig.isMD} isCoderQA={this.props.reviewProcessingUIConfig.isCoderQA}
                                userID={this.props.reviewProcessingUIConfig.userID} memberID={this.props.reviewProcessingUIConfig.memberInternalKey} />
                        </Tab>
                    }
                    {
                        this.props.reviewProcessingUIConfig.includeOpportunityTabs &&
                        <Tab eventKey={3} title="Opportunities">
                            <OpportunityCapture opportunityData={this.state.opportunityData} medicalRecordReviewID={this.props.reviewProcessingUIConfig.medicalRecordReviewID}
                                userID={this.props.reviewProcessingUIConfig.userID} memberID={this.props.reviewProcessingUIConfig.memberInternalKey}
                                isQA={this.props.reviewProcessingUIConfig.isQA} isComplete={this.props.reviewProcessingUIConfig.isComplete}
                                isMD={this.props.reviewProcessingUIConfig.isMD} isCoderQA={this.props.reviewProcessingUIConfig.isCoderQA}
                                rejectionReasons={this.state.rejectionReasons} getOpportunityData={this.getOpportunityData}
                                getOpportunityHistoryData={this.getOpportunityHistoryData} opportunityReviewComments={this.state.opportunityReviewComments}
                                commentGroupOptions={this.state.commentGroupOptions} pecCorrectionCommentSelected={this.props.pecCorrectionCommentSelected}
                                toast={this.props.toast} />
                        </Tab>
                    }
                    {
                        this.props.reviewProcessingUIConfig.includeOpportunityTabs &&
                        <Tab eventKey={4} title="Opportunities History">
                            <OpportunityCaptureHistory opportunityData={this.state.opportunityHistoryData}
                                memberID={this.props.reviewProcessingUIConfig.memberInternalKey} />
                        </Tab>
                    }
                    {
                        this.props.reviewProcessingUIConfig.includeStarsTab &&
                        <Tab eventKey={5} title="Stars">
                            <StarsCapture starsData={this.state.starsData} medicalRecordReviewID={this.props.reviewProcessingUIConfig.medicalRecordReviewID}
                                isQA={this.props.reviewProcessingUIConfig.isQA} isMD={this.props.reviewProcessingUIConfig.isMD} qualityMeasures={this.state.qualityMeasures}
                                isComplete={this.props.reviewProcessingUIConfig.isComplete} rejectionReasons={this.state.starsRejectionReasons} imageRecords={this.state.imageRecords}
                                userID={this.props.reviewProcessingUIConfig.userID} memberID={this.props.reviewProcessingUIConfig.memberInternalKey}
                                maxDateDifference={this.props.reviewProcessingUIConfig.maxDayDifferenceBetweenDateOfServiceFromAndDateOfServiceTo}
                                getStarsData={this.getStarsData} selectedStarMeasure={this.props.selectedStarMeasure} clearSelectedStarMeasure={this.props.clearSelectedStarMeasure}
                                member={member} getStarsHistoryData={this.getStarsHistoryData} mrrProviderNPIs={this.state.mrrProviderNPIs} toast={this.props.toast} />
                        </Tab>
                    }
                    {
                        this.props.reviewProcessingUIConfig.includeStarsTab &&
                        <Tab eventKey={6} title="Stars History">
                            <StarsCaptureHistory starsData={this.state.starsHistoryData} />
                        </Tab>
                    }
                </Tabs>
            // </Loader>
        );
    }
}

export default CaptureContainer;
