import React from 'react';
import { Col, Glyphicon, Grid, Panel, Row } from 'react-bootstrap';

const Info = ({ infoType = 'default' }) => {
    const infoMessages = {
        default: 'No Data to display.',
        noData: 'No Data to display.'
    };
    return (
        <Grid fluid style={{maxWidth: window.innerWidth, marginTop: '4rem'}}>
            <Row>
                <Col xs={10} xsOffset={1}>
                    <Panel>
                        <div className="panel-body">
                            <h4><Glyphicon glyph="info-sign" style={{color: "#ccc", marginRight: "1rem"}} />{infoMessages[infoType]}</h4>
                        </div>
                    </Panel>
                </Col>
            </Row>
        </Grid>
    );
}
export default Info;